import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import healthbg from "../HomeAssets/join us background.png";



const HealthAccess = () => {
  return (
    <Box sx={{padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, backgroundColor: '#FCFAF8',   margin: '8vh auto' ,borderRadius:'4vh'}}>
      <Grid container spacing={2} style={{}}>
          <Grid item xs={12} md={6} sx={{textAlign:'center',borderRadius:'2vh', backgroundColor:'#E2E7F3',paddingTop:{xs:'0vh',md:'-30vh'}}}>
             <img src={healthbg} alt="For Employers" style={{width:"90%", height:'auto'}} />
             <h4 style={{ marginTop:'-20vh',fontSize:'4vh',lineHeight:'2.5rem',color:'#113356',fontFamily:'Inter Display'}}>Good health should never<br></br>cost a fortune</h4>
             <p style={{marginBottom:'250px',fontSize:'3vh',lineHeight:'2.5rem',color:'#113356',fontFamily:'Inter Regular'}}>...but the reality is far from that in Africa</p>
          </Grid>

        <Grid item xs={12} md={6} sx={{marginTop:{xs:'10vh',md:'10vh'}}}>
          <Typography  style={{margin:'32px 0',fontFamily:'Inter',fontSize:'3.3vh', lineHeight: '5vh',color:'#113356', fontWeight:'400'}}>
            <p style={{display:'flex',borderBlockStyle:'ridge',borderBottom:'none', margin:'3vh',padding:'3vh',color:'#113356'}}> <p style={{margin:'3vh',color:'#113356', fontSize:'6vh'}}>5% </p> African households have access to health insurance</p>
            <p style={{display:'flex',borderBlockStyle:'ridge', borderBottom:'none', margin:'1vh',padding:'3vh',color:'#113356'}}> <p style={{margin:'3vh',color:'#113356', fontSize:'6vh'}}>20%</p> Households borrow monthly for health bills. Corresponding to $500M in 2023 in Kenya</p>
            <p style={{display:'flex',borderBlockStyle:'ridge',borderBottom:'none', margin:'1vh',padding:'3vh',color:'#113356'}}> <p style={{margin:'3vh',color:'#113356', fontSize:'6vh'}}>85%</p> Africans are excluded from health insurance.  About 300M families</p>
            <p style={{display:'flex',borderBlockStyle:'ridge', margin:'1.5vh',padding:'3vh',color:'#2c4a66'}}> <p style={{margin:'3vh',color:'#113356', fontSize:'6vh'}}>95% </p>Banked households are uninsured even though 58% of Africa is banked</p>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HealthAccess;
import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import futureafricaicon from "../HomeAssets/future africa logo 1.png"
import visaicon from "../HomeAssets/image 2.png"
import philipsicon from "../HomeAssets/image 5.png"
import accelerateicon from "../HomeAssets/image 6.png"

const OurPartners = () => {
  return (
    <Box  sx={{  padding:{xs:2,md:4}, width:{xs:'96%',md:'90%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
      <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'3vh',md:'7vh'},paddingLeft:{xs:'12vh',md:'15vh'},fontFamily: 'Inter Display', color:'#113356'}}>
      Supported by
      </Typography>
      <Grid container spacing={4} justifyContent="center" style={{height:'auto'}} >
        <Grid item xs={8} sm={6} md={2.5} style={{paddingTop:'10vh'}}>
          <Card sx={{ }} style={{ }}>
            <CardContent>
               <img src={futureafricaicon} alt=''style={{width:'100%'}}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8} sm={6} md={2.5}style={{paddingTop:'10vh'}}>
          <Card sx={{}} style={{}}>
            <CardContent>
              <img src={visaicon} alt=''style={{width:'100%'}}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8} sm={6} md={2.5}style={{paddingTop:'10vh'}}>
          <Card sx={{ }} style={{}}>
            <CardContent>
              <img src={philipsicon} alt=''style={{width:'70%'}}/>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8} sm={6} md={2.5} style={{paddingTop:'10vh'}}>
          <Card sx={{ }} style={{}}>
            <CardContent>
              <img src={accelerateicon} alt=''style={{width:'100%'}}/>
            </CardContent>
          </Card>
        </Grid>
       
      </Grid>
   
    </Box>
  );
};

export default OurPartners;
import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';


const PrivacyPolicy = () => {
  return (
<div className="section-container">
   <Navbar/>
  
    <Box  sx={{  padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
     
      <Grid container spacing={4} justifyContent="center" style={{height:'auto',marginTop:'-20px'}} >
        
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: '#FCFAF8' }} style={{paddingTop:'0vh'}}>
            <CardContent >
               <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
               Privacy Policy of CheckUpsMed
              </Typography>
             
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Last updated: June, 5,2024
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              This Privacy Policy describes our policies and procedures on the collection,  and disclosure of your information when you use the Service
               and tells you about your privacy rights and how the law protects you.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                Your Personal data is used to provide and improve the Service. By using the Service, you agree to the collection and use of 
                information in accordance with this Privacy Policy.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Interpretation and Definitions
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Interpretation
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have 
              the same meaning regardless of whether they appear in singular or in plural.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Definitions
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              For the purposes of this Privacy Policy:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               Account means a unique account created for You to access our Service or parts of our Service.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Business, for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers'
               personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such 
               information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal 
               information, that does business in the State of California.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to CheckUps Medical Ltd., together with 
              its holding, parent, sister or subsidiary companies and including Checkupsmed.com website.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              For the purpose of the GDPR, the Company is the Data Controller.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Consumer, for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, 
              as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual 
              who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Country refers to: all countries for which this policy would apply
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Cookies are small files that are placed on your computer, mobile device or any other device by a website, containing the details of 
              your browsing history on that website among its many uses.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Data Controller, for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which 
              alone or jointly with others determines the purposes and means of the processing of Personal Data.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Do Not Track (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC),
               for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Personal Data is any information that relates to an identified or identifiable individual.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, 
              online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, 
              or could reasonably be linked, directly or indirectly, with You.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Sale, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, 
              making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer's personal 
              information to another business or a third party for monetary or other valuable consideration.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Service refers to the Website and such offerings directly from checkupsmed.com or the Company.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies
               or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services 
               related to the Service or to assist the Company in analyzing how the Service is used. For the purpose of the GDPR, Service Providers
                are considered Data Processors.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure 
              itself (for example, the duration of a page visit).
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              We/Us means the Company aforementioned.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Website refers to <a href='https://checkupsmed.com/PrivacyPolicy' target='blank'>www.checkupsmed.com/PrivacyPolicy</a> 
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              You means the individual accessing or using the Service, or the company, institution, or other legal entity on behalf of which such 
              individual is accessing or using the Service, as applicable.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Collection and Use Your Personal Data
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Types of Data Collected
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
            Personal Data
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You.
              Personally identifiable information may include, but is not limited to:
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Email address
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             First name and last name
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Phone number
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Address, Country, County/Province, City, Road, Postal Address, Physical Address
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Usage Data
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Usage Data is collected automatically when using the Service.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of 
             mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile 
             Internet browser You use, unique device identifiers and other diagnostic data.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Tracking Technologies and Cookies
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are 
             beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when 
             a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted 
             Your browser setting so that it will refuse Cookies, our Service may use Cookies.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to 
             as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or 
             opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while
              Session Cookies are deleted as soon as You close Your web browser.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We use both Session and Persistent Cookies for the purposes set out below:
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Necessary / Essential Cookies
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Type: Session Cookies
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Administered by: Us
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. 
             They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Cookies Policy / Notice Acceptance Cookies
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Type: Persistent Cookies
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Administered by: Us
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Functionality Cookies
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Type: Persistent Cookies
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Administered by: Us
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference.
              The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Tracking and Performance Cookies
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Type: Persistent Cookies
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Administered by: Third-Parties
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information 
             gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how our users react to them.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy <a href='https://checkupsmed.com/CookiesPolicy' target='blank'>www.checkupsmed.com/CookiesPolicy</a>  .
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Use of Your Personal Data
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Company may use Personal Data for the following purposes:
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             To provide and maintain our Service, including to monitor the usage of our Service.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities 
             of the Service that are available to You as a registered user.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services
              You have purchased or of any other contract with Us through the Service.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             To contact You: To contact You by email, telephone calls, whatsapp, SMS, or other equivalent forms of electronic communication, such as a mobile 
             application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, 
             including the security updates, when necessary or reasonable for their implementation.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to 
             those that you have already purchased or enquired about unless You have opted not to receive such information.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             To manage Your requests: To attend and manage Your requests to Us.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, 
             or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our
              Service, products, services, marketing and your experience.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We may share Your personal information in the following situations:
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service,
              for payment processing, to contact You.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             For business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, 
             sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. 
             Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             With business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             With other users: when You share personal information or otherwise interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed outside.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             With Your consent: We may disclose Your personal information for any other purpose with Your consent.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Retention of Your Personal Data
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your
             Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality 
             of Our Service, or We are legally obligated to retain this data for longer time periods.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Transfer of Your Personal Data
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. 
             It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or 
             other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no 
             transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the
              security of Your data and other personal information.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Disclosure of Your Personal Data
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Business Transactions
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal 
             Data is transferred and becomes subject to a different Privacy Policy.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Law enforcement
              </Typography>

             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid
              requests by public authorities (e.g. a court or a government agency).
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Other legal requirements
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Comply with a legal obligation
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Protect and defend the rights or property of the Company
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Prevent or investigate possible wrongdoing in connection with the Service
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Protect the personal safety of Users of the Service or the public
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Protect against legal liability
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Security of Your Personal Data
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic
              storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Detailed Information on the Processing of Your Personal Data
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Service Providers We use may have access to Your Personal Data. These third-party vendors collect, store, use, process and transfer information 
             about Your activity on Our Service in accordance with their Privacy Policies.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Analytics
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We may use third-party Service providers to monitor and analyze the use of our Service.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Google Analytics
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the
              use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on.
              The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Email Marketing
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You.
             You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Payments
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal
              information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council,
               which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             PayPal
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Their Privacy Policy can be viewed at <a href='https://www.paypal.com/webapps/mpp/ua/privacy-full' target='blank'>https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Braintree
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Their Privacy Policy can be viewed at <a href='https://www.braintreepayments.com/legal/braintree-privacy-policy' target='blank'>https://www.braintreepayments.com/legal/braintree-privacy-policy</a> 
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             FastSpring
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Their Privacy Policy can be viewed at< a href=' http://fastspring.com/privacy/' target='blank'> http://fastspring.com/privacy/</a>
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Usage, Performance and Miscellaneous
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We may use third-party Service Providers to provide better improvement of our Service.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Awin and ShareASale
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Their Privacy Policy can be viewed at < a href=' https://www.awin.com/us/privacy#how-we-use-data-from-advertisers-and-publishers' target='blank'> https://www.awin.com/us/privacy#how-we-use-data-from-advertisers-and-publishers</a>
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             GDPR Privacy
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Legal Basis for Processing Personal Data under GDPR
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We may process Personal Data under the following conditions:
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Consent: You have given Your consent for processing Personal Data for one or more specific purposes.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Performance of a contract: Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Legal obligations: Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Vital interests: Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Public interests: Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Legitimate interests: Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision
              of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Your Rights under the GDPR
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             You have the right under this Privacy Policy, and by law if You are within the EU, to:
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Request access to Your Personal Data. The right to access, update or delete the information We have on You. Whenever made possible, you can access, 
             update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Request correction of the Personal Data that We hold about You. You have the right to have any incomplete or inaccurate information We hold about You corrected.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Object to processing of Your Personal Data. This right exists where We are relying on a legitimate interest as the legal basis for Our processing and
              there is something about Your particular situation, 
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are
             processing Your Personal Data for direct marketing purposes.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Request erasure of Your Personal Data. You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Request the transfer of Your Personal Data. We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used,
              machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Withdraw Your consent. You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may
              not be able to provide You with access to certain specific functionalities of the Service.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Exercising of Your GDPR Data Protection Rights
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You
              to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             The Company is the Data Controller of Your Personal Data collected while using the Service. 
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             CCPA Privacy
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             This privacy notice section for California residents supplements the information contained in Our Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of California.
             </Typography>
             <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
             Categories of Personal Information Collected
              </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular
              Consumer or Device. The following is a list of categories of personal information which we may collect or may have been collected from California residents within the last twelve (12) months.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in 
             fact collected by Us, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been collected. For example, certain categories of personal information would only be collected if You provided such personal information directly to Us.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Category A: Identifiers.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: Yes.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: Yes.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Category C: Protected classification characteristics under California or federal law.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: No.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Category D: Commercial information.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: Records and history of products or services purchased or considered.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: Yes.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Category E: Biometric information.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: No.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Category F: Internet or other similar network activity.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: Interaction with our Service or advertisement.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: Yes.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Category G: Geolocation data.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: Approximate physical location.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: No.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
            
             Category H: Sensory data.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: Audio, electronic, visual, thermal, olfactory, or similar information.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: No.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Examples: Current or past job history or performance evaluations.
             </Typography>
             <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
             Collected: No.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Collected: No.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Category K: Inferences drawn from other personal information.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Collected: No.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Under CCPA, personal information does not include:
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Publicly available information from government records
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Deidentified or aggregated consumer information
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Information excluded from the CCPA's scope, such as:
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Sources of Personal Information
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We obtain the categories of personal information listed above from the following categories of sources:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Directly from You. For example, from the forms You complete on our Service, preferences You express or provide through our Service, or from Your purchases on our Service.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Indirectly from You. For example, from observing Your activity on our Service.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Automatically from You. For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         From Service Providers. For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors for payment processing, or other third-party vendors that We use to provide the Service to You.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Use of Personal Information for Business Purposes or Commercial Purposes
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We may use or disclose personal information We collect for "business purposes" or "commercial purposes" (as defined under the CCPA), which may include the following examples:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         To operate our Service and provide You with our Service.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You provide Your personal information to purchase a product or service, We will use that information to process Your payment and facilitate delivery.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           As described to You when collecting Your personal information or as otherwise set forth in the CCPA.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         For internal administrative and auditing purposes.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the "Use of Your Personal Data" section.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         If We decide to collect additional categories of personal information or use the personal information We collected for materially different, unrelated, or incompatible purposes We will update this Privacy Policy.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Disclosure of Personal Information for Business Purposes or Commercial Purposes
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We may use or disclose and may have used or disclosed in the last twelve (12) months the following categories of personal information for business or commercial purposes:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category A: Identifiers
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category D: Commercial information
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category F: Internet or other similar network activity
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been disclosed.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         When We disclose personal information for a business purpose or a commercial purpose, We enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.
           </Typography>
           <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
           Sale of Personal Information
          </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer's personal information by the business to a third party for valuable consideration. This means that We may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Please note that the categories listed below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact sold, but reflects our good faith belief to the best of our knowledge that some of that information from the applicable category may be and may have been shared for value in return.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We may sell and may have sold in the last twelve (12) months the following categories of personal information:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category A: Identifiers
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category D: Commercial information
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Category F: Internet or other similar network activity
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Share of Personal Information
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We may share Your personal information identified in the above categories with the following categories of third parties:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Service Providers
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Payment processors
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Our affiliates
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Our business partners
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Sale of Personal Information of Minors Under 16 Years of Age
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We do not knowingly collect personal information from minors under the age of 16 through our Service, although certain third party websites that we link to may do so. These third-party websites have their own terms of use and privacy policies and we encourage parents and legal guardians to monitor their children's Internet usage and instruct their children to never provide information on other websites without their permission.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We do not sell the personal information of Consumers We actually know are less than 16 years of age, unless We receive affirmative authorization (the "right to opt-in") from either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a request to Us by contacting Us.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal information, please contact Us with sufficient detail to enable Us to delete that information.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Your Rights under the CCPA
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The CCPA provides California residents with specific rights regarding their personal information. If You are a resident of California, You have the following rights:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The right to notice. You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The right to request. Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The categories of personal information We collected about You
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The categories of sources for the personal information We collected about You
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Our business or commercial purpose for collecting or selling that personal information
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The categories of third parties with whom We share that personal information
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The specific pieces of personal information We collected about You
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The categories of personal information categories sold
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The categories of personal information categories disclosed
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The right to say no to the sale of Personal Data (opt-out). You have the right to direct Us to not sell Your personal information. To submit an opt-out request please contact Us.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The right to delete Personal Data. You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Debug products to identify and repair errors that impair existing intended functionality.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us.
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Comply with a legal obligation.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Make other internal and lawful uses of that information that are compatible with the context in which You provided it.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The right not to be discriminated against. You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Denying goods or services to You
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Providing a different level or quality of goods or services to You
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Exercising Your CCPA Data Protection Rights
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         By visiting this page on our website: < a href='https://checkupsmed.com/ContactUs' target='blank'>https://www.checkupsmed.com/contact/</a>
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Only You, or a person registered with the California Secretary of State that You authorize to act on Your behalf, may make a verifiable request related to Your personal information.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Your request to Us must:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'2.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Provide sufficient information that allows Us to reasonably verify You are the person about whom We collected personal information or an authorized representative
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Describe Your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We cannot respond to Your request or provide You with the required information if we cannot:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Verify Your identity or authority to make the request
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         And confirm that the personal information relates to You
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Any disclosures We provide will only cover the 12-month period preceding the verifiable request's receipt.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         For data portability requests, We will select a format to provide Your personal information that is readily useable and should allow You to transmit the information from one entity to another entity without hindrance.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Do Not Sell My Personal Information
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a verifiable consumer request from You, we will stop selling Your personal information. To exercise Your right to opt-out, please contact Us.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on the Service that sells personal information as defined by the CCPA law. If you wish to opt out of the use of Your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that You use.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Website
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The NAI's opt-out platform: <a href='http://www.networkadvertising.org/choices/' target='blank'>http://www.networkadvertising.org/choices/</a> 
           </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           The EDAA's opt-out platform <a href='http://www.youronlinechoices.com/' target='blank'>http://www.youronlinechoices.com/</a> 
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The DAA's opt-out platform: < a href='http://optout.aboutads.info/?c=2&lang=EN' target='blank'>http://optout.aboutads.info/?c=2&lang=EN</a> 
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         The opt-out will place a cookie on Your computer that is unique to the browser You use to opt-out. If you change browsers or delete the cookies saved by your browser, You will need to opt-out again.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Mobile Devices
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Your mobile device may give You the ability to opt out of the use of information about the apps You use in order to serve You ads that are targeted to Your interests:
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         "Limit Ad Tracking" on iOS devices
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         You can also stop the collection of location information from Your mobile device by changing the preferences on Your mobile device.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         "Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Our Service does not respond to Do Not Track signals.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Children and Minor's Privacy
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
           </Typography>
           <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
           Your California Privacy Rights (California's Shine the Light law)
          </Typography>
           <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
           Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact Us using the contact information provided below.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         To request removal of such data, and if You are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Links to Other Websites
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Changes to this Privacy Policy
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
         </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
         </Typography>
         <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
         Contact Us
          </Typography>
         <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
         If you have any questions about this Privacy Policy, You can contact us:
         By visiting this page on our website:< a href='https://checkupsmed.com/ContactUs/' target='blank'> https://www.checkupsmed.com/contact/</a>
           </Typography>
        
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
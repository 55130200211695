import axios from 'axios';
import {HOST_API_KEY}  from '../config-global';

const api = axios.create({
  baseURL: HOST_API_KEY || '',
  headers: {
    "Content-Type": 'application/json',
    Accept:'application/json'
  },
});

/**
 * @description Intercept the request and insert
 * auth header if required
 */
api.interceptors.request.use((config) => {

    return config;
});


export default api;
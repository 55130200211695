import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { ClassNames } from '@emotion/react';
import resources1 from "../HomeAssets/resources image 1.png";
import resources2 from "../HomeAssets/resources image 2.png";
import resources3 from "../HomeAssets/resources image 3.png";
import howtoenrollvideo from "../HomeAssets/enrolling.mp4";


const CaseStudies = () => {
  return (
    <Box  sx={{ padding:{xs:2,md:4}, width:{xs:'96%',md:'90%'}, margin: '80px auto', backgroundColor:'#F8FAFC',borderRadius:'4vh'}}>
      <Typography variant="h4" gutterBottom style={{fontSize:'4vh',fontFamily: 'Inter Regular',color:'#113356'}}>
       Case Studies
      </Typography>
      <Grid container spacing={4} justifyContent="center" style={{height:'auto'}} >
        <Grid item xs={12} sm={6} md={4} style={{paddingTop:'10vh'}}>
          <Card sx={{ }} style={{borderColor:'#113356', borderStyle:'groove', borderRadius:'2vh', borderWidth:'0.3vh'}}>
            <CardContent>
              <img src={resources1} alt='' style={{width: '95%', borderRadius: '1vh', alignItems: 'center',display: 'block',margin:'1vh auto',marginBottom: '3vh'}}/>
              <Typography variant="h6" style={{fontFamily: 'Inter Display', fontSize:'3vh',color:'#113356'}}>
                How to request for services on COVA
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{paddingTop:'10vh'}}>
          <Card sx={{ }} style={{borderColor:'#113356', borderStyle:'groove', borderRadius:'2vh', borderWidth:'0.3vh'}}>
            <CardContent>
              <Typography variant="h6" style={{fontFamily: 'Inter Regular', fontSize:'3vh',color:'#113356'}}>
                <img src={resources2} alt=''style={{width: '95%', borderRadius: '1vh', alignItems: 'center',display: 'block',margin:'1vh auto',marginBottom: '3vh'}}/>
              </Typography>
              <Typography variant="h6" style={{fontFamily: 'Inter Display', fontSize:'3vh',color:'#113356'}}>
              How to top up your COVA credit
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4} style={{paddingTop:'10vh'}}>
          <Card sx={{  }} style={{borderColor:'#113356', borderStyle:'groove', borderRadius:'2vh', borderWidth:'0.3vh'}}>
            <CardContent>
              <Typography>
              <img src={resources3} alt=''style={{width: '95%', borderRadius: '1vh', alignItems: 'center',display: 'block',margin:'1vh auto',marginBottom: '3vh'}}/>
              </Typography>
              <Typography variant="h6" style={{fontFamily: 'Inter Display', fontSize:'3vh',color:'#113356'}}>
             How to enroll into Checkups COVA
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudies;
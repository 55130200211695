import React from "react";
import "../Styles/Footer.css";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { Link } from "react-router-dom";
import NavFooter from "./NavFooter";
import emailicon from "../HomeAssets/email icon.svg";
import linkedinicon from "../HomeAssets/LinkedIn icon.svg";
import phoneicon from "../HomeAssets/Phone icon.svg";
import WhatsAppicon from "../HomeAssets/WhatsApp icon.svg";


function Footer() {
  return (
  
    <div className="footer-section">
        <NavFooter/>
      <div className="footer-container">
    
        <div className="ft-info">
       
          <div className="ft-info-p1">
          
              <ul className="ft-social-links">

                    <li>
                        <a
                          href="mailto:info@checkUpsmed.com"
                          title="Email"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           <img src={emailicon} alt=""/>
                            </a>
                    </li>
                    <li>
                      <a
                            href="https://www.linkedin.com/company/checkups-medical/mycompany/"
                            title="LinkedIn"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <img src={linkedinicon} alt=""/>
                      </a>
                    </li>

                  <li>
                        <a
                            href="https://facebook.com/"
                            title="Phone Call"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <img src={phoneicon} alt=""/>
                          </a>
                    </li>

                    <li>
                          <a
                              href="https://wa.me/+25411050290"
                              title="WhatsApp"
                              target="_blank"
                              rel="noopener noreferrer"
                              >
                              <img src={WhatsAppicon} alt=""/>
                        </a>
                  </li>
              </ul> 
            </div>
        </div>

        <div className="location-container">
           <div className="footer-location">
             <p>
             Airport Trade Center
              Jomo Kenyatta International Airport
               Nairobi, Kenya
             </p>
           </div> 
           <div className="footer-location">
              <p>
                  GA Insurance House
                  Ralph Bunche Road
                  Upperhill, Nairobi, Kenya
                </p>
           </div>
            <div className="footer-location">
              <p >
                  DRS House
                  Lusaka Road
                  Nairobi, Kenya
              </p>
           </div>
            <div className="footer-location">
              <p >
                Munuki-Gudele Roundabout
                (Seventh Day Roundabout)
                Customs Road, Jurlika Street
                Juba, South Sudan
              </p>
           </div>
          </div>

      </div>


         <div className="ft-copyright">
                <p  className="ft-copyright">
                  CheckUps COVA (C) 2024. All Rights Reserved.
                </p>
             <p className="ft-copyright">

                 <Link to="/TermsAndConditions">
                  <a href=''>Terms and Conditions</a> 
                  </Link> 
                  <p className="ft-copyright">
                    <Link to="/SecurityPolicy">
                     <a href=''>Security Policy</a> 
                     </Link>
                  </p> 

                  <p className="ft-copyright">
                  <Link to="/PrivacyPolicy">
                     <a href=''>Privacy Policy</a> 
                     </Link>
                  </p> 
                  <p className="ft-copyright">
                      <Link to="/CookiesPolicy">
                      <a href=''> Cookies Policy</a> 
                      </Link>
                  </p> 
                  <p className="ft-copyright">
                      <Link to="/CheckupsDisclaimer">
                      <a href=''>Checkups Disclaimer</a> 
                      </Link>
                  </p> 
             </p>
            
        </div>
     </div>
   
  );
}

export default Footer;

import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Button, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import joinusbg from "../HomeAssets/join us background.png";
import Navbar from './Navbar';
import Footer from './Footer';

const SubmitForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    surname: '',
    phoneNumber: '',
    idNumber: '',
    occupation: '',
    dob: '',
    nationality: '',
    email: '',
    county: '',
    homeLocation: '',
    officeLocation: '',
    gender: '',
    agentCode: '',
    companyName: '',
    paymentMethod: '',
    subscribeCheckups: false,
    receiveMonthlyReport: false,
    shareInformation: false,
  });

  const [numExtraFields, setNumExtraFields] = useState(0);
  const [extraFieldValues, setExtraFieldValues] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  const handleNumFieldsChange = (event) => {
    const numFields = parseInt(event.target.value, 10);
    setNumExtraFields(numFields);
    setExtraFieldValues(Array.from({ length: numFields }, () => ({})));
  };

  const handleExtraFieldChange = (index, event) => {
    const { name, value } = event.target;
    const newValues = [...extraFieldValues];
    newValues[index] = {
      ...newValues[index],
      [name]: value
    };
    setExtraFieldValues(newValues);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const dataToSend = {
      ...formData,
      dependents: extraFieldValues
    };

    try {
      const response = await fetch('https://api.example.com/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSend)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      alert('Form submitted successfully');
      // Optionally, reset the form fields after successful submission
      setFormData({
        firstName: '',
        middleName: '',
        surname: '',
        phoneNumber: '',
        idNumber: '',
        occupation: '',
        dob: '',
        nationality: '',
        email: '',
        county: '',
        homeLocation: '',
        officeLocation: '',
        gender: '',
        agentCode: '',
        companyName: '',
        paymentMethod: '',
        subscribeCheckups: false,
        receiveMonthlyReport: false,
        shareInformation: false,
      });
      setExtraFieldValues([]);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const renderExtraFields = () => {
    return extraFieldValues.map((field, index) => (
      <Grid container spacing={2} key={index}>
        {/* Render your extra fields here */}
        {/* Example: */}
        <TextField
          fullWidth
          label={`Dependent ${index + 1} Surname`}
          name={`dependents[${index}].surname`}
          value={field.surname || ''}
          onChange={(e) => handleExtraFieldChange(index, e)}
        />
        {/* Add more fields as needed */}
      </Grid>
    ));
  };

  return (
    <div>
      <Navbar />
      <Box sx={{
        padding: { xs: 2, md: 2 },
        width: { xs: '96%', md: '95%' },
        borderRadius: '4vh',
        margin: '80px auto',
        backgroundImage: `url('${joinusbg}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            {/* Content for left side */}
          </Grid>
          <Grid item xs={12} md={6} sx={{ padding: '0.1vh', marginTop: { xs: '0.1vh', md: '0vh' } }}>
            <form onSubmit={handleSubmit}>
              {/* Form content */}
              {/* Example: */}
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              {/* Add all other form fields */}
              {/* Example: */}
              {renderExtraFields()}
              {/* Add dependent fields */}
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};

export default SubmitForm;

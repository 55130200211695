import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';


const AboutCheckups = () => {
  return (
    <Box  sx={{ padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'},  margin: '80px auto', backgroundColor:'#FCFAF8',borderRadius:'4vh'}}>
      <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'3vh',md:'5.5vh'},fontFamily: 'Inter Display', textAlign:'Left',fontWeight: '500',lineHeight: '40px',color:'#113356'}}>
      The Solution?
    
      </Typography>
      <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'4.5vh',md:'6vh'},fontFamily: 'Inter', textAlign:'Left',fontWight: '400',lineHeight: '7vh',color:'#113356'}}>
      CheckUps COVA!!!
      </Typography>
      <Grid container spacing={4} justifyContent="center" style={{height:'auto'}} > 
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: 'rgba(226,231,243,1)' }} style={{paddingTop:'0vh'}}>
            <CardContent>
              <Typography  sx={{color:'#113356',fontFamily: 'Inter', fontSize:{xs:'2.3vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'},fontWeight: '400',lineHeight: '6vh', borderRadius:'4vh'}}>
              CheckUps COVA is a groundbreaking health financing and benefits management platform.
              Our platform enables banks, SMEs, and families to finance and unlock 4x more health
              benefits compared to market prices. By doing so, we minimize delays in care due to
              financial hardship for their clients, employees, and extended families across Africa.
              </Typography>
              <Typography sx={{color:'#113356',fontFamily: 'Inter', fontSize:{xs:'2.3vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'},fontWeight: '400',lineHeight: '6vh'}}>
              CheckUps COVA helps clients pay, unlock 4x more value for every $1 paid, and share health
              benefits with their loved ones. We're not just a platform; we're a movement disrupting the
              way healthcare is financed in Africa.
              </Typography>
              <Typography sx={{color:'#113356',fontFamily: 'Inter', fontSize:{xs:'2.3vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'},fontWeight: '400',lineHeight: '6vh'}}>
              Founded by Moka Lantum and Renee Ngamau, CheckUps COVA is at the forefront of
              revolutionizing healthcare financing across the African continent. With 4 Urgent Care
              Centers in Kenya and 1 in South Sudan, we're committed to ensuring that no one has to
              choose between their health and their financial well-being and expanding access to quality
              healthcare.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        
        
      </Grid>
 
    </Box>
  );
};

export default AboutCheckups;
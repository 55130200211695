import { useMutation } from 'react-query';
import api from './api';
import {
  makeErrorMessage,
  // useRedirectOnUnauthorised,
} from './useGracefulAPIErrorHandling';

/**
 * @description Abstract the logic for making a post request into a reusable function
 * @param payload
 * @param path
 * @returns
 */
export default function usePostRequest(
  path,
  payload,
  onSuccess,
  onFailure,
) {
  const postRequest = () => api.post(path, payload);
  const mutation = useMutation(postRequest, {
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      onFailure({
        message: makeErrorMessage(error),
        statusCode: error.response?.status,
      });
    },
  });
  return mutation;
}
import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import illustrator20 from "../HomeAssets/$2,000,000 illustration.svg";
import womenillustrator from "../HomeAssets/protect women business illustration.svg"
import illustrator14 from "../HomeAssets/14,000+ illustration.svg";
import vouched from "../HomeAssets/vouched for illustration.svg";



const ImpactStories = () => {
  return (
    <Box  sx={{  padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, textAlign: 'center', margin: '80px auto',backgroundColor:'#FCFAF8',borderRadius:'4vh'
    }}>
      <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'4.5vh',md:'7vh'},fontFamily: 'Inter Display',color:'#113356'}}>
        
        
        Our impact and success <br></br>stories so far
      </Typography>
      <Grid container spacing={4} justifyContent="center" style={{height:'auto'}} >
        <Grid item xs={12} sm={6} md={3.5} style={{paddingTop:'10vh'}}>
          <Card sx={{ backgroundImage: `url('${vouched}')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat', }} style={{height:'30vh', paddingTop:'7.5vh'}}>
            <CardContent>
              <Typography variant="h6" sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'},color:'#113356'}}>
                Vouched for by
              </Typography>
              <Typography variant="h6" sx={{fontFamily: 'Inter Regular',  fontSize:{xs:'2.5vh',md:'3.5vh'},color:'#113356'}}>
                IBM, VISA, SwissRe.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4.5}>
          <Card sx={{ backgroundColor: '#e0e7ff',
            backgroundImage: `url('${illustrator20}')`,
            backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
           }} style={{height:'38vh', paddingTop:'8.5vh'}}>
            <CardContent>
              <Typography variant="h2" sx={{fontFamily: 'Inter Display', fontSize:{xs:'4vh',md:'5.5vh'},color:'#113356'}}>
                $2,000,000
              </Typography>
              <Typography variant="p"sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'},color:'#113356'}}>
                in savings achieved for participants in COVA's financial solutions
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3.5} style={{paddingTop:'10vh'}}>
          <Card sx={{ backgroundColor: '#fff' ,
            backgroundImage: `url('${womenillustrator}')`,
            backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
          }} style={{height:'30vh', paddingTop:'8.5vh'}}>
            <CardContent>
              <Typography variant="h6" sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'},color:'#113356'}}>
                Protects women-owned businesses
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3.5}>
          <Card sx={{ backgroundColor: 'white' }} style={{height:'0vh',paddingTop:'0px'}}>
           
            <CardContent>
              <Typography variant="h6" style={{fontFamily: 'InterDisplay-Regular', fontSize:'4vh'}}>
               {/*  14,000+ */}
              </Typography>
              <Typography variant="h6"style={{fontFamily: 'InterDisplay-Regular', fontSize:'3.5vh'}}>
            {/*     households enrolled on the COVA platform in 12 months. */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{paddingTop:'2.5vh'}}>
          <Card sx={{ backgroundColor: '#f0f4fc',
            backgroundImage: `url('${illustrator14}')`,
            backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
          }} style={{height:'40vh', paddingTop:'4.5vh'}}>
           
            <CardContent>
              <Typography variant="h6" sx={{fontFamily: 'Inter Display', fontSize:{xs:'4.8vh',md:'6vh'},color:'#113356'}}>
                14,000+
              </Typography>
              <Typography variant="h6"sx={{fontFamily: 'Inter Regular', fontSize:'3.5vh',color:'#113356'}}>
                households enrolled on the COVA platform in 12 months.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4.5} style={{paddingTop:'6vh'}}>
          <Card sx={{ backgroundColor: '#113356', color: '#ffffff' }} style={{height:'40vh', paddingTop:'6.5vh'}}>
          
            <CardContent>
              <Typography variant="h2" sx={{fontFamily: 'Inter Display', fontSize:{xs:'5vh',md:'5.8vh'}}}>
                380,000+
              </Typography>
              <Typography variant="p" sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}}}>
                on-demand deliveries for drugs, labs, and consultations to date.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    
    </Box>
  );
};

export default ImpactStories;
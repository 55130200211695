export const customerReviews = [
    {
      "name": "- Mukuma Gabriel,  ",
      "location": "Kenya",
      "message": "COVA helped me and my family. I am eternally grateful to them for the 4X benefits. Even my extended family members were able to partake of the services courtesy of my subscription.."
    },
     {
      "name": "- Akinyi Odhiambo  ",
      "location": "Kenya",
      "message": "Before I became a CheckUps COVA member. I was tired because of the long wait times in the hospital but I appreciated the short times and them coming to me whether at work or my place of work"
    },
    {
      "name": "- Abdi Kadir Muhammad  ",
      "location": "Kenya",
      "message": "CheckUps has been my go to hospital since they came to my home and treated me, called to interpret results and deliver medication"
    },
    {
      "name": "- Anastasia Kipyegon  ",
      "location": "Kenya",
      "message": "My mum visited the clinic and she said that the staff at CheckUps were very professional and the care received was of the highest quality."
    },
    {
      "name": "- Beatrice Nduku  ",
      "location": "Kenya",
      "message": "Being a single mother of four, when CheckUps visited the market I work and introduced the CheckUps COVA, I signed up and till to date, they have not disappointed."
    },
    {
      "name": "- Collins Odhiambo  ",
      "location": "Kenya",
      "message": "The clinic's environment was clean and comforting. The nurses and doctors provided top - notch care"
    },
    {
      "name": "- Martin Musyoka  ",
      "location": "Kenya",
      "message": "I couldn't be happier with the service I received. I choose them any day any time."
    },
    {
      "name": "- Joseph Kerimi  ",
      "location": "Kenya",
      "message": "I ordered medication while I was at work and not feeling well. I called CheckUps and they brought the meds right at my workplace!"
    },
    {
      "name": "- Edwin Wafula  ",
      "location": "Kenya",
      "message": "I took the CheckUps COVA for my mum and dad back at shagz and I can't explain the joy being able to save the finances."
    },
    {
      "name": "- Jamila Hassan  ",
      "location": "Kenya",
      "message": "This medical center exceeded my expectations. The attention to detail and patient care were outstanding."
    } 
];

import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';


const IbmBlog = () => {
  return (

  
    <Box  sx={{  padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
     
      
      
      <Grid container spacing={4} justifyContent="center" style={{height:'auto',marginTop:'-20px'}} >
        
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: '#FCFAF8' }} style={{paddingTop:'0vh'}}>
            <CardContent >
            <Typography variant="h5" gutterBottom 
          sx={{backgroundColor:'rgba(202,89,134,0.4)',
               padding:'15px', 
               fontSize:{xs:'2vh',md:'3.5vh'},
               width:{xs:'60%',md:'35%'},
               marginLeft:{xs:"1vh",md:'2.5vh'},
               borderRadius:'2.5vh',
               fontFamily:'Inter Regular',
               marginTop:'2.5vh',
               color:'#113356',}}>
            IBM Z and LinuxONE Community
          </Typography>

               <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
               CheckUps Leverages IBM LinuxONE for High-Performance AI Inferencing and Secure Data Serving
              </Typography>
              <Typography  sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontStyle:'italic'}} >
              IBM LinuxONE 4 offers an ideal infrastructure for predictive AI workloads, enabling businesses to drive value with speed and scale
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              By: Dr Moka Lantum, CEO, CheckUps (Nairobi, Kenya)
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              We recognize the important role that infrastructure plays in the success of our projects
              and data management initiatives. With its on-chip AI accelerator, IBM LinuxONE 4 is engineered for speed and scale, providing
              a dedicated resource for AI inferencing to enable huge levels of throughput. IBM internal testing found that IBM LinuxONE
              Emperor 4 can process up to 3.5 million inference requests per second with 1ms
              response time using a Credit Card Fraud Detection model1. By exploring the potential, startups and small to medium-sized 
              businesses may have the opportunity to enhance their operations and potentially deliver additional value to their customers.

              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              In addition to its high-performance AI capabilities, IBM LinuxONE is designed with security at its core. Its built-in security features, 
              such as pervasive encryption and secure execution environments, are engineered to protect sensitive data, giving

              businesses the tools to innovate and grow in a complex and regulated landscape.
              Moreover, it is designed with a focus on energy efficiency, which helps optimize power consumption and contribute to a more sustainable IT infrastructure.

              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                We utilize IBM LinuxONE through the IBM Hyper Protect Accelerator program which is a start up accelerator and technical mentorship program.
                The program supports startups leveraging highly sensitive data to improve the security of, and access to, digital assets,financial, healthcare,
                AI and other services. IBM provides selected startups with support to enable confidential computing so that startups can protect sensitive
                customer data via IBM’s flagship offering known as IBM Hyper Protect Services, which runs on IBM LinuxONE. Startups can also leverage IBM’s 
                compatible set of products and services, including machine learning, artificial intelligence (watsonx), digital assets and blockchain.
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               As part of the IBM Hyper Protect Accelerator program, we are committed to empowering our customers with accessible and affordable 
               healthcare solutions with the industry-leading security that IBM Hyper Protect offers. Our mission is to revolutionize the way people
               access financing and care, making it as simple and convenient as using their mobile phones. We believe that no one should have to 
               choose between their health and their financial stability, and we're dedicated to breaking down the barriers that prevent people 
               from getting the care they need.
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                Our health benefits and care management platform leverages cutting-edge technology to ensure quality, efficiency, and scalability. 
                By combining advanced security features with real-time AI-powered analytics, we enable our frontline clinical teams to make informed,
                data-driven decisions that optimize patient outcomes. Our platform streamlines the healthcare journey, from financing to treatment,
                making it seamless and stress-free for our customers. With our innovative approach, we're not just changing the way healthcare is 
                delivered - we're transforming lives, one patient at a time.
                As a part of the IBM Hyper Protect Accelerator Program, CheckUps is excited to be working closely with IBM to bring new innovations 
                to our customers.

              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                To learn more about CheckUps, please visit our website here <a href='https://checkupsmed.com/' target="blank">(www.checkupsmed.com)</a>. To learn more about IBM LinuxONE 4 and AI 
                visit  <a href='https://community.ibm.com/zsystems/home/' target="blank">IBM Z and LinuxONE Community</a> and sign up.
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2vh',md:'2.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontStyle:'italic'}}>
                1. DISCLAIMER: Performance result is extrapolated from IBM internal tests running
                local inference operations in an IBM LinuxONE Emperor 4 LPAR with 48 cores and 128 GB memory on Ubuntu 20.04 (SMT mode) 
                using a synthetic credit card fraud detection model <a href='https://github.com/IBM/ai-on-z-fraud-detection' target="blank">(https://github.com/IBM/ai-on-z-fraud-detection)</a> exploiting the Integrated

              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2vh',md:'2.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontStyle:'italic'}}>
              Accelerator for AI. The benchmark was running with 8 parallel threads each pinned to   the first core of a different chip. 
              The lscpu command was used to identify the core-chip topology. A batch size of 128 inference operations was used.
               Results may vary.

              </Typography>


            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IbmBlog;
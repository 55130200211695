import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import About from "./Components/About";
import ContactuS from "./Components/ContactUs";
import Resources from "./Components/Resources";
import Hero from "./Components/Hero";
import NewsUpdates from "./Components/NewsUpdates";
import HyperProtectAIOnLinuxOne from "./Components/hyper-protect-ai-on-linuxone"
import TermsAndConditions from "./Components/TermsAndConditions";
import SecurityPolicy from "./Components/SecurityPolicy";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import CookiesPolicy from "./Components/CookiesPolicy";
import CheckupsDisclaimer from "./Components/CheckupsDisclaimer";
import RegistrationForm from "./Components/RegistrationForm";
import DynamicForm from "./Components/DynamicForm";
import SubmitForm from "./Components/SubmitForm";


function App() {
  return (
    <div className="App">
      <Routes>
          <Route path="" element={<Hero/>} />
          <Route path="/Resources" element={<Resources/>} />
          <Route path="/About" element={<About/>}/>
          <Route path="/ContactUs" element={<ContactuS/>}/>
          <Route path="/NewsUpdates" element={<NewsUpdates/>}/>
          <Route path="/resources/hyper-protect-ai-on-linuxone" element={<HyperProtectAIOnLinuxOne/>}/>
          <Route path="/TermsAndConditions" element={<TermsAndConditions/>}/>
          <Route path="/SecurityPolicy" element={<SecurityPolicy/>}/>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
          <Route path="/CookiesPolicy" element={<CookiesPolicy/>}/>
          <Route path="/CheckupsDisclaimer" element={<CheckupsDisclaimer/>}/>
          <Route path="/RegistrationForm" element={<RegistrationForm/>}/>
          <Route path="/SubmitForm" element={<SubmitForm/>}/>

        </Routes>
    </div>
  );
}

export default App;

import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';


const CookiesPolicy = () => {
  return (
    <div className="section-container">
    <Navbar/>
  
    <Box  sx={{  padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
     
      
      
      <Grid container spacing={4} justifyContent="center" style={{height:'auto',marginTop:'-20px'}} >
        
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: '#FCFAF8' }} style={{paddingTop:'0vh'}}>
            <CardContent >
               <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
               Cookies Policy for Checkups Medical
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Last updated: June 5, 2024
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Introduction
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               Checkups Medical, its holding, parent, sister, or subsidiary companies ("We", "Us", or "Our") respect your privacy and are committed to protecting it through our compliance with this Cookies Policy. This policy explains what Cookies are, how We use Cookies, your choices regarding Cookies, and how We strive to ensure compliance with various privacy laws and regulations.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              What are Cookies?
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Cookies are small text files that are placed on Your computer, mobile device, or any other device by a website. They contain details of your browsing history on that website and enable the website to remember your preferences and actions.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Types of Cookies We Use
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              1. Necessary / Essential Cookies:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              These Cookies are crucial for the proper functioning of the Website. They allow Us to authenticate users, prevent fraudulent activities, and provide the services You have requested. These Cookies are essential for using certain features of the Website.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              2. Functionality Cookies:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Functionality Cookies enable the Website to remember the choices You make, such as your login credentials, language preferences, and location. These Cookies aim to provide You with a more personalized experience and eliminate the need to re-enter your preferences each time You visit the Website.
               </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               3. Analytics and Performance Cookies:
              </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               We use Analytics and Performance Cookies to gather information about the traffic to the Website and how users interact with it. This data may include information that directly or indirectly identifies You as an individual visitor. We use this information to analyze trends, administer the Website, track users' movements, and collect demographic information for aggregate use.
               </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               Your Choices Regarding Cookies
              </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               You have the option to disable Cookies in your browser settings and delete existing Cookies associated with the Website. Please note that disabling Cookies may cause some inconvenience and impact the functionality of certain features on the Website.
               </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               For more information on managing Cookies in your browser, please refer to your browser's documentation or visit the official web pages of your browser:
             </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                - Google Chrome:<a href=' https://support.google.com/accounts/answer/32050' target='blank'> https://support.google.com/accounts/answer/32050</a>
               </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               - Internet Explorer:<a href=' http://support.microsoft.com/kb/278835' target='blank'> http://support.microsoft.com/kb/278835</a>
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                - Mozilla Firefox:< a href='https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored' target='blank'>https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a> 
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                - Apple Safari:< a href=' https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac' target='blank'> https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
                </Typography>
                <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
                Compliance with Privacy Laws and Regulations
              </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                1. General Data Protection Regulation (GDPR):
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                For users in the European Economic Area (EEA), We process personal data collected through Cookies in accordance with the GDPR. We obtain your explicit consent for the placement of non-essential Cookies and provide you with the ability to withdraw your consent at any time.
               </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                  2. California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA):
               </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                  Under the CCPA and CPRA, California residents have the right to opt-out of the sale of their personal information. We do not sell personal information collected through Cookies to third parties.
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                3. California Online Privacy Protection Act (CalOPPA):
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                In compliance with CalOPPA, We inform You that our Privacy Policy provides details about the personally identifiable information (PII) that may have been collected about You and the choices available to You regarding the use of that information.
                </Typography>
                <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
                Changes to this Cookies Policy
              </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                We may update our Cookies Policy from time to time. We will notify You of any changes by posting the new Cookies Policy on this page and updating the "Last updated" date at the top of this Cookies Policy.
                </Typography>
                <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
                Contact Us
              </Typography>

                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                If you have any questions, concerns, or suggestions regarding this Cookies Policy, please contact Us:
               </Typography>
               <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              By email: < a href='mailto:info@checkUpsmed.com' target="_blank">info@checkupsmed.com</a>
              </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                By phone: (+254) (0) 111 050 290
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                By WhatsApp: <  a href="https://wa.me/+25411050290" target="_blank"> +25411050290</a>
                </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    <Footer/>
    </div>
  );
};

export default CookiesPolicy;
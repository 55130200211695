import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';


const TermsAndConditions = () => {
  return (
<div className="section-container">
     <Navbar/>
    <Box  sx={{  padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
     
      
      
      <Grid container spacing={4} justifyContent="center" style={{height:'auto',marginTop:'-20px'}} >
        
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: '#FCFAF8' }} >
            <CardContent >
               <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold', textAlign:'center'}}>
               CHECKUPSMED.COM WEBSITE TERMS AND CONDITIONS (Referred to as T&C)
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'},  margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               We advise you to carefully read these Terms and Conditions prior to using checkupsmed.com
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                By using checkupsmed.com, the User agrees to the following:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                Checkupsmed.com is managed by CheckUps Medical Ltd. The website offers a variety of services and products related to Payment Services, 
                Management Services, Software and Applications, and value-added Services as included on the website from time to time (“The Services”).
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                The Services listed on checkupsmed.com may be provided by CheckUps its parent, sister, subsidiary companies and/or other various 
                approved third-party providers and distributors.
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The use of checkupsmed.com is subject to the T&C stipulated. The T&C constitute an agreement between CheckUps and the User of checkupsmed.com.

              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The User is requested to read the T&C as they determine their rights and obligations. The use of this website and the web pages 
              linked to checkupsmed.com constitute a definite and irrevocable acceptance on the part of the User to all the T&C. The User and/or 
              anyone on their behalf shall not have any claim and/or demand, whether direct or indirect, against CheckUps and/or their owners, and/or
               anyone acting on their behalf, except as provided here within.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps is the company which manages the business and is the party with which the User enters into a contractual agreement. 
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Data, Information, Security, and Privacy
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The personal details that the User submits on the website, including the details of their purchase(s), shall be retained by CheckUps 
              for their database. CheckUps shall not provide this information to any third party, except to their service providers and only for completing the order.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              – (a) CheckUps has put the required resources and takes measures to prevent others from entering checkupsmed.com and infringing upon
               the privacy of the checkupsmed.com Users. However, it is impossible to totally prevent others from entering the website and obtaining 
               the information contained within, without CheckUps knowledge and authorization. Therefore, the User hereby declares that they shall not 
               have any claims and/or demands, whatever sort, against CheckUps and/or anyone acting on their behalf, arising from such unauthorized use 
               of the information, if it shall regretfully occur.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              – (b)  CheckUps is a registered Data Controller within the laws of Kenya and protects the User data by means of technical and organizational 
              security measures against accidental or manipulation, loss, destruction, or access by unauthorized or unwarranted persons. Our security measures,
               such as data encryption, are regularly enhanced in accordance with technological development. Except for the limited rights to use the services,
                the User does not acquire any interest in the services or data obtained by CheckUps including customer data, and all aggregate data which may
                 include User data and data received from others. Data is defined as data provided by the User to CheckUps  or obtained by CheckUps through the 
                 issuance of pixels via website(s), online media, and such other digital presence as made available to the User or. Without limitation, as between
                  CheckUps and the User, CheckUps owns, and shall continue to own, all intellectual property and other proprietary rights in and to all portions of 
                  the services and the data. The User hereby grants CheckUps the limited right to use the this data to perform the services as well as provide 
                  value-added Services offerings as may be required from time to time and further authorizes  CheckUps to place such data in a database controlled 
                  by CheckUps where it may be combined with the data of others to form aggregate data for analysis and the performance of services for the User and,
                   with regard to such aggregate data, perform services for others. In providing services for others CheckUps agrees not to disclose that said 
                   aggregate data containing personal data unless such disclosure is agreed to by the User.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Ownership and Rights
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Checkupsmed.com, and such related trademarks and intellectual properties contained within this website are the property of CheckUps
               and/or of other third parties that place content on checkupsmed.com. The User must not alter and/ or cause to alter the content contained 
               within checkupsmed.com. Furthermore, the User must not copy and/or commercialize, in any way, directly and/or indirectly, data and/or 
               information, and/or any other element contained, and/or is a part of checkupsmed.com.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Nothing contained on checkupsmed.com should be construed as granting or conveying any license and/or any right to use any of the
               propriety rights displayed on checkupsmed.com, nor any waiver of any kind, of those rights by their authors.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The use of any trademarks and/or other propriety rights, contained in checkupsmed.com, on any other website and/or in any other 
              environment is strictly prohibited. The User must not post and/or transmit any unlawful, and/ or immoral material, and/or any other
               material that could constitute and/or encourage conduct that would be considered a criminal offence, and/or give rise to civil
                liability and/or otherwise in violation of any law.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Illegal and/or unwarranted use of checkupsmed.com and/or of the information contained in checkupsmed.com shall constitute a breach of
               ownership of intellectual properties, and a violation of propriety laws. The User hereby declares that they know that checkupsmed.com 
               and all the information and/or data contained within, as well as the form and design of checkupsmed.com, are the exclusive property of CheckUps, and in
                the case of third parties’ advertisements or contents, the property of those other third parties.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                  CheckUps and any third party whose contents are on checkupsmed.com make no warranty whatsoever in regard to checkupsmed.com and 
                  the Services and products advertised in checkupsmed.com, all of which are provided on an “as is” basis. CheckUps and any third-party
                  providers and/or distributors, do not warrant the accuracy and/or completeness and/or reliability of any of the content and/or information
                  and/or data contained on checkupsmed.com, and expressly disclaim, to the maximum extent permitted by the applicable law, all warranties and
                  conditions, including implied warranties and conditions of merchant and sub-merchant ability and/or fitness for a particular purpose, and
                  non-infringement, and those arising by statute and/or otherwise in law and/or from a course of dealing and/or usage of trades. 
                  checkupsmed.com also includes merchant information over which CheckUps has no supervisory control. CheckUps does not authenticate
                   this information and will not bear any liability for any mistake, and/or deceit, and/or discrepancy, and/or missing information included therein.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Copyright Clause
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              This website is copyright protected by the laws of Kenya and by International Treaties. No part of this website may be saved or stored, reproduced, 
              used or transmitted in any form or by any electronic or mechanical means, including, but not limited to storage thereof by e-mail or any other means, 
              and the use thereof on any other website and/or any other media form, without the written and express permission of CheckUps.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Liability Clauses
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The liability of CheckUps is limited by the terms of contract as well as by international conventions and local laws. Before making
               an order for a specific service, booking and reserving a service through checkupsmed.com, it is recommended to read the specific customer 
               contract of the service provider that operates the underlying service (known as the Merchant terms of service). If the User wishes to 
               receive the contract conditions of the service provider, they can contact CheckUps at the stipulated contact details, and this information,
                if available, shall be given to them under the condition that the User has obtained service through CheckUps.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              If the Services ordered by the User are not Services that are provided by CheckUps, CheckUps shall be considered only as an intermediary
               between the User and the service provider and therefore CheckUps shall not be liable, directly and/or indirectly, to any damage and/or loss,
                of whatever sort or kind, due to the faulty and/or improper performance of those Services and the liability, in this regard, shall be borne by 
                the service providers who shall acknowledge full responsibility on service matters, according to prevailing terms of service.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps and/or anyone acting on their behalf do not warrant that the website is free from viruses and/or other malicious components that
               may damage the User’s computer and/or other equipment and therefore shall not be responsible, under any circumstances, and/or liable for 
               any damages, of whatever sort or kind, caused by such viruses and/or other components.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              In no event, shall CheckUps and/or any third-party provider be liable for any injury and/or loss, and/or claim and/or damage, and/or
               any special and/or exemplary and/or punitive and/or indirect and/or incidental and/or consequential damage of any kind (including, 
               but not limited, to lost profits and/or lost savings and/or revenues), whether based in contract, tort, strict liability, or otherwise,
                which arises out, or in any way connected with, any use of checkupsmed.com and/or any failure and/or delay (including, but not limited to,
                 the use, or inability of use, of any component of checkupsmed.com), the performance and/or non-performance by CheckUps and/or any third-party 
                 provider and/or distributor, even if CheckUps and/or any such third party has been advised of the possibility of such damages.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The User shall indemnify and hold harmless CheckUps and/or anyone acting on their behalf and/or any third-party provider and
               distributor and their officers, directors, employees, and agents, from and against any claims and/or demands including for all 
               the expenditures and/or damages and/or legal costs that CheckUps may be liable to due to the usage of checkupsmed.com by the User.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Change, Modifications, and Cessation of the Website and Offered Services
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps may cease at any time the activity of checkupsmed.com and/or cancel the Services offered in it, including, but not limited to, all or some 
              of the links contained in checkupsmed.com to other websites.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                CheckUps may cancel the purchase of the Services by the User, without any prior notice. CheckUps may refuse access or cease access
                to checkupsmed.com or to any part of it, to any User and at any phase, at their sole discretion and without any prior notice.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps may change and modify, from time to time, the website’s structure and/or outlook and/or T&C of use and/or the scope and 
              availability of their offered Services and/or any other element connected to the website, the operation, and the services, at their sole discretion and without prior notice.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              All pictures and images displayed on checkupsmed.com are for demonstration purposes only and do not, in any of form, constitute 
              any obligation of whatever sort on CheckUps‘s part.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Information displayed on checkupsmed.com that is given to CheckUps by third parties is under the sole responsibility of those parties, 
              and CheckUps shall not be responsible and/or liable to any mistakes and/or misrepresentation, of whatever sort regarding such information.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Use of checkupsmed.com Website
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The User may use checkupsmed.com to make legitimate orders or purchase different Services and must not use checkupsmed.com for
               any other purposes, including without limitation, to make any speculative, false, or fraudulent order and/or any order in anticipation of demand.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Whenever and wherever applicable, an order through checkupsmed.com can be paid only by the forms of payment represented in checkupsmed.com.
               Any person who owns a valid credit card or other form of payment may purchase Services through CheckUps. Without detracting from the above 
               stated, a condition precedent for the execution of the purchase order through checkupsmed.com is the receipt of the approval of the 
               User’s credit card company or form of payment provider for the purchase.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              checkupsmed.com is intended for the provision of information on different Services and products and for the receipt of information
               in this regard to such Services and products. No other use is permitted, unless a specific separate contract dictates any additional use of checkupsmed.com.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              If the User represents that they are of a sufficient legal age to use checkupsmed.com and to create binding legal obligations for any
               liability that they may incur as a result of the use of checkupsmed.com, he shall be financially responsible for all uses of
                checkupsmed.com by the User and those using his login information.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Service Order Procedures
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
               The User can either call at stipulated contact details or enter in the chosen order’s details, the form of payment details,
               and all other details requested by the order form. The User shall be solely responsible for all errors while entering the 
               different requested details, including, but not limited to, errors in choosing the service details and/or services that the User wishes to purchase.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps and/or third-party providers and/or distributors shall not be responsible and/or liable, directly and/or indirectly, if the order and/or
               some of the order’s details shall not be accepted by the system, for whatever reason and/or cause.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps shall not be responsible for any technical and/or other problems that shall prevent the User from purchasing the services on checkupsmed.com.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Entering false details, including invalid credit card details and/or details of credit cards that the User has no right to use, is a criminal 
              offence. Legal action can be taken against anyone entering false information, including civil suits for damages, which CheckUps and/or 
              any third-party providers and/or distributors, might incur due to, or regarding, the entering of false information.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps and/or third-party providers and/or distributors are under no obligation to accept the User’s order and/or to give it
               priority over other orders including orders that were made later.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Links from checkupsmed.com to Other Websites
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              checkupsmed.com contains links to different commercial websites and other internet pages (hereinafter: “the other websites”).
               CheckUps has no control over the other websites, and the use of them is subject to the terms, conditions, and other instructions
                detailed in the other websites, if such exist. The websites are provided only for the User’s convenience and are not endorsed
                 by CheckUps in any way or form.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              CheckUps shall not be liable in any way whatsoever to the contents of the other websites and the inclusion of the links are not, 
              in any way, confirmations of whatever sort, on CheckUps’s behalf, as to the truthfulness and/or correctness of the information
               contained on the other websites.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              If the User shall decide to access the other websites, they do so at their own risk. Every transaction that the User will carry 
              out on the other websites shall be executed directly between the User and the other website and/or the website owners and/or their
               operators and/or anyone acting on their behalf, without any involvement on the part of CheckUps and/or anyone acting on CheckUps’s
                behalf, and CheckUps and/or anyone acting on their behalf shall not be liable, in any way or form, to any act and/or transaction
                 and/or purchase that shall take place in the other websites.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              By using checkupsmed.com, the User agrees and acknowledges that they will have no claim and/or demand, of any kind or form, against 
              CheckUps and/or anyone acting on their behalf for any use and/or act and/or transaction and/or purchase of products and/or Services
               that will take place on the other websites.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              If CheckUps and/or anyone acting on its behalf, shall try out of their goodwill and on exceptional basis to assist in solving a problem of
               a User on checkupsmed.com that chose to carry out a transaction on the other websites, this assistance shall not be interpreted in any way
                as putting any liability, of whatever kind or form, on CheckUps and /or anyone acting on its behalf. Similarly, nothing shall be interpreted in
                 these T&C as creating any liability of whatever sort or kind on CheckUps and/or anyone acting on its behalf, for any act, whatsoever, that the 
                 User has taken on the other websites or by means of the other websites.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Severability
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              These T&C, together with those incorporated herein or referred to herein, constitute the entire agreement between CheckUps and 
              the User, relating to the subject matter hereof, and supersedes any prior understandings and/or agreements (whether oral and/or written)
               regarding the subject matter, and may not be amended and/or modified except in writing or by making such amendments or 
               modifications available on checkupsmed.com.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Additional T&C may apply to orders, purchases of goods and/or Services and other uses of aspects of  checkupsmed.com, and the User 
              agrees to abide by such other T&C.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The T&C shall be deemed severable. If any provision shall be determined to be unenforceable and/or invalid, such provision shall nonetheless
               be enforced to the fullest extent permitted by the applicable law, and such determination shall not affect the validity and enforceability of
                any other remaining provisions.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Governing Law
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The law of Kenya only, shall govern this agreement. The Courts of Kenya shall have the exclusive jurisdiction in any dispute and/or complaint 
              and/or the claim regarding the use of checkupsmed.com.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              All information on this website is the property of CheckUps. Reproduction in part or whole is strictly forbidden unless express consent is given by management.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontStyle:'italic'}}>
              For any queries please email <a href='mailto:info@checkUpsmed.com' target="blank">info@checkupsmed.com </a> or contact us on <a href='https://checkupsmed.com/ContactUs' target="blank">www.checkupsmed.com/contact</a> 
              </Typography>

            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    <Footer/>
    </div>
  );
};

export default TermsAndConditions;
import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import bullets from "../HomeAssets/image.png";
import enrollimage from "../HomeAssets/enrollNow.jpg";
import whitearrow from "../HomeAssets/white-arrow.png";
import RegistrationForm from './RegistrationForm';

const EnrollNow = () => {
  return (
    <Box sx={{ padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'}, backgroundColor: 'rgba(226,231,243,1)',  margin: '80px auto' ,borderRadius:'4vh' }}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{}}>
             <img src={enrollimage } alt="For Employers" style={{width: '95%', height:'auto', borderRadius:'4vh'}} />
          </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom style={{padding:'1vh', width:'60vh', borderRadius:'3.5vh', fontFamily:'Inter Regular', fontSize:'4vh', marginTop:'1vh', borderStyle:'groove', backgroundColor:'rgba(202,89,134,0.4)', color:'#113356',margin:'4vh 2.5vh'}}>
              BE A PART. ENROLL NOW
          </Typography>
          <Typography variant="h6"sx={{width: 'auto',fontFamily: 'Inter Display',fontSize: {xs:'4vh',md:'6vh'}, marginTop: '1.5vh',color:'#113356', margin:'0.5vh 1.5vh'}}>
              Start planning for a healthier future with CheckUps COVA.
          </Typography>

         

          <Button variant="contained" color="primary" sx={{backgroundColor:'#113356',fontFamily:'Inter', borderRadius:'2.5vh',width:'70%', fontSize:{xs:'2vh',md:'3.5vh'},padding:'1.5vh 1.5vh',marginTop:'1vh',textTransform:"none",margin:'4vh 2.5vh'}}>
              Send a message to join
              <img src={whitearrow} alt='' style={{width: '2.5vh',marginleft: '1.5vh'}}/>
          </Button>

          <Typography  sx={{margin:'2.5vh 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2.3vh',md:'4vh'}, lineHeight: '3rem',margin:'2vh 1.5vh'}}>
            * What to expect
              <p ><img src={bullets} alt=""style={{marginRight:'1.5vh'}}/>4X more health benefits for your family</p>
              <p><img src={bullets} alt="" style={{marginRight:'1.5vh'}}/>Avoid painful losses due to expensive bills and care delays</p>
              <p><img src={bullets} alt="" style={{marginRight:'1.5vh'}}/>Protect your family assets and dignity</p>
              <p><img src={bullets} alt="" style={{marginRight:'1.5vh'}}/>Ensure your family's well-being and financial security</p>
          </Typography>
          
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default EnrollNow;
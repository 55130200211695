import {React ,useState} from 'react';
import { Box, Typography, Button, Grid,TextField, Form} from '@mui/material';
import joinusbg from "../HomeAssets/join us background.png";
import jointeam from "../HomeAssets/nurse.jpg";
import usePostRequest  from './usePostRequest.jsx';




const JoinUs= () => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Location: '',
    Year: '',
    AdditionalMessage: ''
  
  });

  const mutation = usePostRequest(
    "api....",
    formData,
   
  );
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutation.mutate();
  };

    const handleReset = () => {
      setFormData({
        FirstName: '',
        LastName: '',
        Email: '',
        Location: '',
        Year: '',
        AdditionalMessage: ''
      
        // Reset other fields as needed
      });
      const onSuccess=()=>{
        console.log('Mutation successful');  
      };
      const onFailure = (error) => {
        console.error('Mutation failed:', error);
        // Handle failure scenario (e.g., show error message)
      };
      };
    

  /*   try {

      const formDataToSend = new FormData();
      formDataToSend.append('FirstName', formData.FirstName);
      formDataToSend.append('LastName', formData.LastName);
      formDataToSend.append('Email', formData.Email);
      formDataToSend.append('Location', formData.Location);
      formDataToSend.append('Year', formData.Year);
      formDataToSend.append('AdditionalMessage', formData.AdditionalMessage);
      formDataToSend.append('Resume', selectedFile);


      const response = await fetch('https://api.example.com/submit', {
        method: 'POST',
      
        body: JSON.formDataToSend
      });

      if (response.ok) {
        // Handle success (e.g., show a success message)
        console.log('Form submitted successfully!');
      } else {
        // Handle error (e.g., show an error message)
        console.error('Form submission failed.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  }; */
  return (
    <Box sx={{padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'},borderRadius:'4vh',
              margin: '80px auto',
              backgroundImage: `url('${joinusbg}')`,
              backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
             }}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{}}>
           <Typography variant="h6"sx={{width: 'auto', color: '#113356',fontFamily: 'Inter Regular',fontSize:{xs:'5vh',md:'7vh'}, marginTop: '20px',lineHeight:'4rem',marginLeft:'5px', textAlign:'left'}}>
           Interested in <br></br>joining the team?
           </Typography>

           <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#2c4a66',}}>
          We are seeking Freelance Dispatch Nurses to join our team and provide high-quality healthcare services to our clients. 
          <br></br>
          <a href='https://wa.me/+25411050290'target="_blank" style={{fontFamily:'Inter Medium', fontSize:'3.5vh'}}>Additional Info</a>
        </Typography >
        
             <img src={jointeam  } alt="For Employers" style={{width:'90%',height:'auto'}} />
          </Grid>

          <Grid item xs={12} md={6}  sx={{padding:'0.1vh', marginTop:{xs:'0.1vh',md:'4vh'}}}>
            <form onSubmit={handleSubmit}>
            <Box component="form" noValidate autoComplete="off">

              <Grid container spacing={2} sx={{padding:{xs:'2.5vh',md:'4vh'}, width:{xs:'95%',md:'100%',margin:'1vh auto', backgroundColor:'#FCFAF8',padding:"1vh", borderRadius:'4vh'}}}>
              
                <Grid item xs={12} sm={6}>
                    <label style={{fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Medium'}}>First Name</label>
                  <TextField 
                    fullWidth 
                    name="FirstName"
                    variant="outlined"
                    onChange={handleChange}
                    required 
                     />
                </Grid>
                <Grid item xs={12} sm={6}>
                <label style={{fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Medium'}}>Last Name</label>
                  <TextField 
                  fullWidth  
                  name="LastName"
                  variant="outlined" 
                  onChange={handleChange}
                  required 
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <label style={{fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Medium'}}>Email Address</label>
                  <TextField 
                  fullWidth 
                  type='Email'
                  name="Email"
                   variant="outlined" 
                   onChange={handleChange}
                   required />
                </Grid>
                <Grid item xs={12} sm={6}>
                <label style={{fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Medium'}}>Location</label>
                  <TextField 
                  fullWidth  
                  name="Location"
                  variant="outlined"
                  onChange={handleChange}
                  required />
                </Grid>
                <Grid item xs={12}>
                <label style={{fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Medium'}}>Years of Experience (not less than 2)</label>
                  <TextField 
                  fullWidth 
                  name="Year"
                   variant="outlined"
                   onChange={handleChange}
                    required />
                </Grid>
                <Grid item xs={12}>
                <label style={{fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Medium'}}>Upload CV/Resume</label>
                  <Button variant="outlined" component="label" fullWidth style={{textTransform:'none', borderColor:'black', color:'#113356', fontFamily:'Inter Medium', fontSize:'2vh'}}>
                  
                    <input type="file"
                    onChange={handleFileChange}
                   
                     />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                <label style={{fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Medium'}}>Additional Message</label>
                  <TextField
                   fullWidth 
                   name="AdditionalMessage"
                   onChange={handleChange}
                   variant="outlined" 
                   multiline rows={10} />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" type='' size="large" fullWidth style={{backgroundColor:'#113356',fontFamily:'Inter Regular',fontSize:'3.5vh', textTransform:'none'}}>
                    Submit application
                  </Button>
                </Grid>
             
              </Grid>
            </Box>
            </form>
          </Grid>
      </Grid>
    </Box>
  );
};

export default JoinUs;
import { CircularProgress, Slide } from '@mui/material';
//import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/system';
import React from 'react';

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '100%',
  height: '100vh',
  backgroundColor: '#3a3a3a50',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: theme.zIndex?.snackbar + theme.zIndex?.tooltip,
}));

const Progress = styled(CircularProgress)(({ theme }) => ({
  zIndex: theme.zIndex?.snackbar + theme.zIndex?.tooltip,
}));

const LoadingSpinner = ({ color = 'secondary' }) => {
  return (
    <Root>
      <Progress color={color} />
    </Root>
  );
};

// const RefreshingCard = ({ message = 'Refreshing ...' }) => (
//   <Snackbar
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'center',
//     }}
//     open
//     autoHideDuration={6000}
//     TransitionComponent={Slide}
//     onClose={() => {}}
//     message={message}
//   />
// );

export default LoadingSpinner;
// export { RefreshingCard };

/* eslint-disable @typescript-eslint/no-unused-vars */

import { styled } from '@mui/system';

import LoadingSpinner from "./LoadingSpinner";

import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import usePostRequest from "../utils/usePostRequest";
import { useForm } from "react-hook-form";
import {Grid, Paper, TextField} from '@mui/material'
import GenericDialog from "./GenericDialog";
import Swal from 'sweetalert2';

const links = [
  {
    pathname: "",
    label: "",
    permission: "",
  },
];
const Root = styled('div')({
    width: '100%',
  });
  
  const Icon = styled('div')(({ theme }) => ({
    fontSize: 20,
    marginRight: theme.spacing(1),
  }));
  
  const Paper2 = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  }));
  
  const InputLabel = styled('label')({
    '&.focused': {
      color: '#449afe',
    },
    color: '#449afe',
  });
  
  const PaperStyled = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    width: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'black !important',
    fontFamily: 'ChronicaPro, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
  }));
  
// type ClearPaymentProps = {
//     queryString?: string;
//     open: boolean;
//     // eslint-disable-next-line no-unused-vars
//     setOpen: any;
//     telephone: string;
//     subscriptionAmount: string;
//     IDNumber:string;
//     FirstName: string;
//   };
  
  function ClearPayments({
    queryString,
    open,
    setOpen,
    telephone,
    subscriptionAmount,
    IDNumber,
    FirstName
  }) {

  const queryClient = useQueryClient();

  const [remarks, setRemarks] = useState("");

  const { handleSubmit, reset } = useForm();
  const [AmountPaid, setAmountPaid] = useState('')
  const [PhoneNumber, setPhoneNumber] = useState("")


  useEffect(()=>{
    setPhoneNumber(telephone);
  },[telephone]);

  useEffect(()=>{
    setAmountPaid(subscriptionAmount);
  },[subscriptionAmount]);

  const onSuccess = (result) => {
    console.log("result",result);
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.includes("subscriptions"),
    });
   // setConfirmAction(false);
    setOpen(false);
    Swal.fire({
      icon: 'success',
      title: 'STK PUSH PROMPT..',
      text: 'Successfully pushed payment prompt to client',
      showCancelButton: true,
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        window.open('https://checkupsmed.com/RegistrationForm','_self');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        window.open('https://checkupsmed.com/RegistrationForm','_self');

      }
    })
    setRemarks("");
  };
  const onFailure = (err) => {
    setOpen(true);
  };


 //.format("DD-MM-YYYY")
  const handleAmountPaid = (event) => {
    setAmountPaid(event.target.value);
  };
  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const mutation = usePostRequest(
    "pay/post-mpesa-stk-push",
    {
      phoneNumber: PhoneNumber ?  PhoneNumber: telephone,
      totalAmount: AmountPaid ? AmountPaid : subscriptionAmount,
      idNumber:IDNumber,
      firstName:FirstName,
    },
    onSuccess,
    onFailure
  );
  const onSubmit = () => {
    mutation.reset();
    mutation.mutate();
  };
  const actions = [
    {
      label: 'Pay Now',
      primary: true,
      onClick: handleSubmit(onSubmit),
    },
  ];
  

  /*
   * metadata needed to display profile header content
   */
 


  return (
    // <PageContent>
    <Root>
      {/* {isRefreshing ? <RefreshingCard message="Refreshing user" /> : <></>} */}

      <GenericDialog
      title='INITIATE MPESA PROMPT'
      showDialog={open}
      onCloseDialog={() => {
        setOpen(false);
        window.open('https://checkupsmed.com/RegistrationForm', '_self');
      }}
      actions={actions}
      isLoading={mutation.isLoading}
      width="sm"
      height={400}
      verticalAlign="flex-start"
    >
      <Grid item xs={12} sm={12}>
        <PaperStyled>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                type="text"
                InputLabelProps={{
                  classes: {
                    root: InputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                name="PhooneNumber"
                label="Phone Number"
                color="secondary"
                variant="outlined"
                fullWidth
                required
                defaultValue={PhoneNumber}
                value={PhoneNumber}
                onChange={handlePhoneNumber}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                type="number"
                InputLabelProps={{
                  classes: {
                    root: InputLabel,
                    focused: "focused",
                    shrink: "shrink",
                  },
                }}
                name="AmountPaid"
                label="Amount to Pay"
                color="secondary"
                variant="outlined"
                fullWidth
                required
                value={AmountPaid}
                defaultValue={AmountPaid}
                onChange={handleAmountPaid}
                autoComplete="off"
              />
            </Grid>
            {mutation.isLoading ? <LoadingSpinner /> : <></>}
          </Grid>
        </PaperStyled>
      </Grid>
    </GenericDialog>
             
    </Root>
   
  );
};

export default ClearPayments;

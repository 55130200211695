import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";
import Reviews from "./Reviews";
import ImpactStories from "./ImpactStories.jsx";
import ForEmployers from "./ForEmployers.jsx";
import ForSmes from "./ForSmes.jsx";
import HealthAccess from "./HealthAccess.jsx";
import EnrollNow from "./EnrollNow.jsx";
import GetInTouch from "./GetInTouch.jsx";
import JoinUs from "./JoinUs.jsx";
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import MainHero from "./MainHero.jsx";
import AdditionalResource from "./AdditionalResource.jsx";


function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
    <Navbar/> 

      <div onClick={scrollToTop} className={`scroll-up ${goUp ? "show-scroll" : ""}`}>
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
       <MainHero/>
       <ForEmployers/>
       <ForSmes/>
       <HealthAccess/>
       <ImpactStories />
       <Reviews /> 
       <EnrollNow/>
       <GetInTouch/>
       <JoinUs/>
       <AdditionalResource/>
       <Footer/>
   </div>
  );
}

export default Hero;

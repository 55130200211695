import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/NavFooter.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import footerlogo from "../HomeAssets/footer logo.svg";
import footerbullet from "../HomeAssets/footer bullet point.svg";
import { Box, Typography, Button, Grid } from '@mui/material';

function NavFooter() {
  const [navfooter, setNavfooter] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const openNavfooter = () => {
    setNavfooter(!navfooter);
  };

  const handleChatBtnClick = () => {
    if (!isButtonDisabled) {
      toast.info("Experiencing high traffic, Please wait a moment.", {
        position: toast.POSITION.TOP_CENTER,
        onOpen: () => setIsButtonDisabled(true),
        onClose: () => setIsButtonDisabled(false),
      });
    }
  };

  return (
    <div className="navfooter-section">
      <h1 className="navfooter-title">
        <Link to="/">
          <img src={footerlogo} alt="" className="logo"/> 
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navfooter-items">
        <li>
        <Link to="/"style={{textDecoration:'none', color:'white'}} > <img src={footerbullet} alt=""/> Home</Link>
        </li>
        <li>
        <Link to="/Resources"style={{textDecoration:'none', color:'white'}} > <img src={footerbullet} alt=""/> Resources</Link>
        </li>
       
        <li>
             <Link to="/About"style={{textDecoration:'none', color:'white'}} > <img src={footerbullet} alt=""/> About Us</Link>
        </li>
        <li>
             <Link to="/ContactUs"style={{textDecoration:'none', color:'white'}} > <img src={footerbullet} alt=""/> Contact Us</Link>
        </li>
        <li>
             <Link to="/NewsUpdates"style={{textDecoration:'none', color:'white'}} > <img src={footerbullet} alt=""/> News & Updates</Link>
        </li>
    
      </ul>

    
      <div className={`mobile-navfooter ${navfooter ? "open-navfooter" : ""}`}>
        <div onClick={openNavfooter} className="mobile-navfooter-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navfooter-links">
          <li>
            <Link onClick={openNavfooter} to="/" style={{textDecoration:'none'}}>
              Home
            </Link>
          </li>
          <li>
            <Link onClick={openNavfooter} to="/Resources" style={{textDecoration:'none'}}>
              Resources
            </Link>
          </li>
          <li>
            <Link onClick={openNavfooter} to="/About" style={{textDecoration:'none'}}>
              About Us
            </Link>
          </li>
        
          <li>
            <Link onClick={openNavfooter} to="/ContactUs" style={{textDecoration:'none'}}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link onClick={openNavfooter} to="/NewsUpdates" style={{textDecoration:'none'}}>
              News & Updates
            </Link>
          </li>

        </ul>
        <Grid container spacing={2}>
        <Grid item xs={12} md={6} style={{textAlign:'center'}}>
          <Button variant="contained" color="primary" style={{backgroundColor:'#2c4a66',fontFamily:'InterDisplay-Regular', borderRadius:'1.5vh', width:'auto', fontSize: '3.5vh',padding:'14px 20px',marginTop:'2vh',textTransform:"none"}}>
          Send a message 
          </Button>
        </Grid>
      </Grid>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-navfooter">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNavfooter}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default NavFooter;

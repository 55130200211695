import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import whitearrow from "../HomeAssets/white-arrow.png";
import logo from "../HomeAssets/logo.png";

import { createTheme, responsiveFontSizes,ThemeProvider} from '@mui/material/styles';
import { Link } from 'react-router-dom';



let theme = createTheme();
theme = responsiveFontSizes(theme);

const MainHero = () => {

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'}, margin: '4vh auto' }}>
  <Grid container spacing={2} style={{ marginLeft: '0.5vh' }}>
    <Grid item xs={12} md={6}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Inter Regular',
          fontSize: { xs: '5vh', md: '7vh' },
          fontWeight: 400,
          marginTop: '1vh',
          color: '#113356',
          lineHeight: { xs: '7vh', md: '9vh' },
        }}
      >
        Revolutionizing <br />
        Healthcare financing <br />
        throughout Africa
      </Typography>
      <Typography
        sx={{
          margin: '2vh 0.5vh',
          fontFamily: 'Inter Regular',
          fontSize: { xs: '2.5vh', md: '3.5vh' },
          fontWeight: 400,
          lineHeight: { xs: '3.5vh', md: '5.5vh' },
          color: '#113356',
        }}
      >
        With no age limits, no waiting time, and no exclusions for pre-existing
        conditions, COVA is making healthcare accessible to all, reducing
        financial hardships and ensuring timely care for millions across the
        continent so they don't have to postpone care until it is too late.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: '#113356',
          fontFamily: 'Inter Regular',
          borderRadius: '1vh',
          width: { xs: '35%', md: '45%' },
          height:{xs:'10',md:'10%'},
          fontSize: '3.5vh',
          padding: '0.5vh 1.5vh',
          marginTop: '1vh',
          textTransform: 'none',
        }}
      >
      <Link to="/RegistrationForm" style={{color:'white', textDecorationLine:'none'}}> 
        Join Us
        <img src={whitearrow} alt='' style={{ width: '2.5vh', marginLeft: '1vh' }} />
         </Link> 
      </Button>
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        textAlign: 'center',
        minHeight: '75vh',
        backgroundImage: 'url(https://testwebsite.checkupsmed.com/heromap.webp)',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
         <Typography sx={{fontSize:{xs:'1vh',md:'2vh'}, fontFamily:'Inter Regular',marginTop:{xs:'35vh',md:'40vh'},marginLeft:{xs:'33vh',md:'65vh'}, backgroundColor:'white',width:{xs:'0vh',md:'0vh'},position:'flex'}}>
             <a href='https://wa.me/+25411050290'target="blank"> <img src={logo} alt=''/></a>
         </Typography> 
       <Typography sx={{fontSize:{xs:'1vh',md:'3vh'}, fontFamily:'Inter Regular',marginTop:{xs:'0vh',md:'0vh'},marginLeft:{xs:'17vh',md:'25vh'}, backgroundColor:'#FCFAF8',width:{xs:'20vh',md:'50vh'},position:'flex',color:'#113356', borderRadius:'1vh', padding:'1vh', textAlign:'left', lineHeight:'5vh'}}>
        <p>
        Got Questions?
        </p>
        <p>
         <a href='https://wa.me/+25411050290'target="blank" style={{color:'#113356'}}>Click here to talk to an Agent →</a>
        </p>
      </Typography> 
      {/* <img src={map } alt="For Employers" style={{ width: '100%',height:'auto' }} /> */}
    </Grid>
  </Grid>
  <Grid item xs={12} md={9}>
    <Typography
      sx={{
        fontFamily: 'Inter Regular',
        fontSize: { xs: '3vh', md: '4vh' },
        lineHeight: { xs: '3.5vh', md: '5vh' },
        color: '#113356',
        marginLeft: '2vh',
        marginRight: '2vh',
        marginTop: '5vh',
      }}
    >
      At CheckUps COVA, we believe that everyone deserves access to quality healthcare without worrying about the financial burden.
    </Typography>

    <Typography
      sx={{
        fontFamily: 'Inter Regular',
        fontSize: { xs: '2.5vh', md: '4vh' },
        fontWeight: 400,
       
        color: '#113356',
        marginLeft: '2vh',
        marginRight: '2vh',
        marginTop: '5vh',
      }}
    >
      We provide microloans for medical bills by creating inclusive financing options through partnership with banks and microfinance institutions. By offering health benefits and encouraging savings by allowing micropayments, we help loanees to reduce loan defaults, ensure better compliance with treatment and unlock more benefits for our customers, their families and loved ones, when they need them.
    </Typography>
    <Typography
      sx={{
        margin: '5vh auto',
        fontFamily: 'Inter Medium',
        fontSize: { xs: '3vw', md: '4vh' },
        lineHeight: { xs: '2vh', md: '2.5rem' },
        marginLeft: '2vh',
        marginRight: '2vh',
        marginTop: '5vh',
      }}
    >
      <a href='https://wa.me/+25411050290'target="blank" style={{ color: '#113356', fontFamily: 'Inter Regular', fontSize: { xs: '3vw', md: '2.5vh' }, fontWeight: 400 }}>
        WhatsApp (+254) (0) 111 050 290 today to start.
      </a>
    </Typography>
  </Grid>
</Box>
    
    </ThemeProvider>
  );
};

export default MainHero;
import React, { useState } from "react";
import {BrowserRouter,Router,Route,Link} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { toast } from "react-toastify";
import logo from "../HomeAssets/hero logo.svg";
import { Box, Typography, Button, Grid } from '@mui/material';


function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const openNav = () => {
    setNav(!nav);
  };

  const handleChatBtnClick = () => {
    if (!isButtonDisabled) {
      toast.info("Experiencing high traffic, Please wait a moment.", {
        position: toast.POSITION.TOP_CENTER,
        onOpen: () => setIsButtonDisabled(true),
        onClose: () => setIsButtonDisabled(false),
      });
    }
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <Link to="/">
          <img src={logo} alt="" className="logo" style={{}}/> 
        </Link>
      </h1>
      <ul className="navbar-items">
        <li>
          <Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link>
        </li>
          <li>
             <Link to="/Resources"style={{textDecoration:'none', color:'black'}} > Resources</Link>
          </li>
         <li>
            <Link to="/About"style={{textDecoration:'none', color:'black'}}> About Us</Link>
        </li>
        <li>
            <Link to="/ContactUs"style={{textDecoration:'none', color:'black'}}> Contact Us</Link>
        </li>
        <li>
            <Link to="/NewsUpdates"style={{textDecoration:'none', color:'black'}}> News & Updates</Link>
        </li>
      </ul>

      

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/" style={{textDecoration:'none'}}>
              Home
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/Resources" style={{textDecoration:'none'}}>
              Resources
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/About" style={{textDecoration:'none'}}>
              About Us
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/ContactUs" style={{textDecoration:'none'}}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link onClick={openNav} to="/NewsUpdates" style={{textDecoration:'none'}}>
              News & Updates
            </Link>
          </li>
          
        </ul>
        
         <Grid container spacing={2}>
           <Grid item xs={12} md={6} style={{textAlign:'center'}}>
             <Button variant="contained" color="primary" style={{backgroundColor:'#2c4a66',fontFamily:'InterDisplay-Regular', borderRadius:'1.5vh', width:'auto', fontSize: '3.5vh',padding:'14px 20px',marginTop:'2vh',textTransform:"none"}}>
                Send a message
             </Button>
            </Grid>
         </Grid>
  
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;

import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import bgimage from "../HomeAssets/join us background.png";
import logo from "../HomeAssets/logo.png";
import { createTheme, responsiveFontSizes,ThemeProvider } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const MainHeroContact = () => {
  return (
    <ThemeProvider theme={theme}>
   <Box sx={{ padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, margin: '4vh auto' }}>
  <Grid container spacing={2} style={{ marginLeft: '0.5vh' }}>
    <Grid item xs={12} md={6}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Inter Regular',
          fontSize: { xs: '4vh', md: '7vh' },
          fontWeight: 400,
          marginTop: '1vh',
          color: '#113356',
          lineHeight: { xs: '6vh', md: '7vh' },
        }}
      >
        Contact Us
      </Typography>
      
      <Typography
        sx={{
          margin: '2vw 0',
          fontFamily: 'Inter Regular',
          fontSize: { xs: '2vh', md: '3.5vh' },
          fontWeight: 400,
          lineHeight: { xs: '4vh', md: '6vh' },
          color: '#113356',
        }}
      >
       Got enquiries, questions or partnership<br></br> 
       proposals for us ? Get in touch!
      </Typography>
   
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        textAlign: 'center',
        minHeight: '75vh',
        backgroundImage: `url('${bgimage}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
         <Typography sx={{fontSize:{xs:'1vh',md:'2vh'}, fontFamily:'Inter Regular',marginTop:{xs:'40vh',md:'40vh'},marginLeft:{xs:'36vh',md:'65vh'}, backgroundColor:'white',width:{xs:'0vh',md:'0vh'},position:'flex'}}>
             <a href='https://wa.me/+25411050290'target="_blank"> <img src={logo} alt=''/></a>
         </Typography> 
       <Typography sx={{fontSize:{xs:'1vh',md:'3vh'}, fontFamily:'Inter Regular',marginTop:{xs:'0vh',md:'0vh'},marginLeft:{xs:'20vh',md:'35vh'}, backgroundColor:'#FCFAF8',width:{xs:'20vh',md:'50vh'},position:'flex',color:'#113356', borderRadius:'1vh', padding:'2vh', textAlign:'left', lineHeight:'5.5vh'}}>
        <p>
        Got Questions?
        </p>
        <p>
         <a href='https://wa.me/+25411050290'target="_blank" style={{color:'#113356'}}>Click here to talk to an Agent →</a>
        </p>
      </Typography> 
      {/* <img src={map } alt="For Employers" style={{ width: '100%',height:'auto' }} /> */}
    </Grid>
  </Grid>
  
</Box>
    </ThemeProvider>
  );
};

export default MainHeroContact;
import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import employees from "../HomeAssets/for employers Image-min.png";
import whitearrow from "../HomeAssets/white-arrow.png";
import { Link } from 'react-router-dom';
import { createTheme, responsiveFontSizes,ThemeProvider } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const ForEmployers = () => {
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'},backgroundColor: '#FFFFFF',  margin: '80px auto',borderRadius:'4vh'  }}>
      <Grid container spacing={2} style={{marginLeft: '0.5vh'}}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom 
          sx={{backgroundColor:'rgba(202,89,134,0.4)',
               padding:'15px', 
               fontSize:{xs:'2vh',md:'4vh'},
               width:{xs:'30vh', md:'40vh'},
               borderRadius:'2.5vh',
               fontFamily:'Inter Regular',
               marginTop:'2.5vh',
               color:'#113356',}}>
            FOR EMPLOYERS
          </Typography>
          <Typography variant="h6" 
          sx={{width: 'auto',
              fontFamily: 'Inter Display',
              fontSize:{xs:'4vh',md:'6vh'},
              marginTop: '2vh',
              color:'#113356', 
             }}>
            Reduce the burden of health and medical bills on employees
          </Typography>
          <Typography  
          sx={{margin:'4vh 0.5vh',
                fontFamily:'Inter Regular',
                fontSize: {xs:'2.5vh',md:'3.5vh'}, 
                lineHeight: '2.5rem', 
                color:'#113356'}}>
            Ease the financial strain on the company’s workforce. Our innovative platform allows you to pay in advance for your employees’ medical bills hereby reducing absenteeism, save money and increase employee loyalty.
          </Typography>
          <Button variant="contained" color="primary" sx={{backgroundColor:'#113356',fontFamily:'Inter', borderRadius:'1vh', width:'40%', fontSize:{xs:'2vh',md:'3vh'},padding:'1.5vh 1.5vh',marginTop:'1vh',textTransform:"none"}}>
          <Link to="/RegistrationForm" style={{color:'white', textDecorationLine:'none'}}> 
        Get Started
        <img src={whitearrow} alt='' style={{ width: '2.5vh', marginLeft: '1vh' }} />
         </Link> 
          </Button>
        </Grid>
        <Grid item xs={12} md={6} style={{textAlign:'center'}}>
          <img src={employees } alt="For Employers" style={{ width: '90%', height:'auto' }} />
        </Grid>
      </Grid>
    </Box>
    </ThemeProvider>
  );
};

export default ForEmployers;
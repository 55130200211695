import React from 'react';
import { Box, Typography} from '@mui/material';
import { styled } from '@mui/system';
import joinusbg from "../HomeAssets/join us background.png";
import Navbar from './Navbar';
import {  useForm } from "react-hook-form";
import Footer from './Footer';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, Grid,FormGroup,Checkbox , FormControlLabel,RadioBox, FormLabel,RadioGroup,Radio} from '@mui/material';
import { useState } from 'react';
import LocationHome from "./LocationHome";
import LocationOffice from "./LocationOffice";
import  usePostRequest  from "../utils/usePostRequest";
import PaymentModal from "./PaymentModal";
import Iframe from "react-iframe";
import Swal from 'sweetalert2';
import GenericDialog from "./GenericDialog";
import LoadingSpinner from "./LoadingSpinner";

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  position: "relative",
  fontFamily: "ChronicaPro,sans-serif",
  color: "black !important",
}));

const Layout = styled('div')(({ theme }) => ({
  width: 'auto',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  [theme.breakpoints.up(800 + theme.spacing(1) * 1)]: {
    width: '110%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  backgroundColor: "#022c5b",
  color: 'black !important',
}));

const Paper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  width: 'auto',
  [theme.breakpoints.up(800 + theme.spacing(1) * 1)]: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  color: 'black !important',
  fontFamily: "ChronicaPro,sans-serif",
  fontSize: "16px",
  fontWeight: 400,
}));

const Paper2 = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  width: 'auto',
  [theme.breakpoints.up(800 + theme.spacing(1) * 1)]: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const RegistrationForm= () => {
  const { handleSubmit} = useForm();
  const [paymentMethod, setpaymentMethod] = useState('');
  const [pesapalOpen,setPesapalOpen]=useState(false);
  const [amountpayable, setamountpayable] = useState(0);
  const [isAmountChecked, setIsAmountChecked] = useState('');
  const [gender, setgender] = useState('');
  const [numExtraFields, setNumExtraFields] = useState(0);
  const [extraFieldValues, setExtraFieldValues] = useState([]);
  const [refferedBy, setrefferedBy]= useState("");
  const [firstName, setfirstName] = useState("");
  const [middleName, setmiddleName] = useState("");
  const [surname, setsurname] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [IdNumber, setIdNumber] = useState("");
  const [occupation, setoccupation] = useState("");
  const [relationship, setrelationship] = useState("");
  const [dob, setdob] = useState("");

  const [email, setemail] = useState("");
  const [HomeLocation, setHomeLocation] = useState("");
  const [OfficeLocation, setOfficeLocation] = useState("");
  const [AgentDetails, setAgentDetails] = useState('');
  const [OtherAmount, setOtherAmount] = useState('');
  const [Dependents, setDependents] = useState('');
  const [Yes, setYes] = useState('');
  const [ShowDependents, setShowDependents] = useState(false);
  const [ShowAmountField, setShowAmountField] = useState(false);
  const [showAgentDetails, setShowAgentDetails] = useState(false);
  const [showMedicalCreditField, setShowMedicalCreditField] = useState(false);
  const [showVoucherField, setShowVoucherField] = useState(false);
  const [AgentCode, setAgentCode] = useState('');
  const [MedicalCredit, setMedicalCredit] = useState('');
  const [VoucherNo, setVoucherNo] = useState('');
  const [Voucher, setVoucher] = useState('');
  const [AgentName, setAgentName] = useState('');
  const [PackageName, setPackageName] = useState('');
  const [shareInformation, setshareInformation] = useState('');
  const [subscribeCheckups, setsubscribeCheckups] = useState('');
  const [receiveMonthlyReport, setreceiveMonthlyReport] = useState('');
  const [officeLocation, setofficeLocation] = React.useState('');
  const [homeLocation, sethomeLocation] = React.useState('');
  const [officeLat, setOfficeLat] = React.useState(0);
  const [officeLong, setOfficeLong] = React.useState(0);
  const [homeLat, setHomeLat] = React.useState(0);
  const [homeLong, setHomeLong] = React.useState(0);
  const [combinedLocations, setCombinedLocations] = React.useState([]);
  const [amount, setAmount] = useState('');
  const [multipliedAmount, setMultipliedAmount] = useState('');
  const [BankName, setBankName] = useState('');
  const [accountNumber, setaccountNumber] = useState('');
  const [PaySucces, setPaySucces] = useState(false);
  const [MpesaSucces, setMpesaSucces] = useState(false);
  const [pesapal, setPesapal]= useState();
  const [open, setOpen] = useState(false);


  //const classes = useStyles();
 
  const handlepackageChange = (event) => {
    setPackageName(event.target.value);
  };
  const handleReferalChange = (event) => {
    setAgentDetails(event.target.value);
    setShowAgentDetails(event.target.value === 'agent'); // Show extra fields if agent is selected
  };

  const handleDependentsChange = (event) => {
    setDependents(event.target.value);
    setShowDependents(event.target.value === 'Yes'); // Show extra fields if yes is selected

  };


  const handleMedicalCredit = (event) => {
    setMedicalCredit(event.target.value);
    setShowMedicalCreditField(event.target.value === 'MedicalCredit'); // Show extra fields if agent is selected
  };

  const handleVoucher = (event) => {
    setVoucher(event.target.value);
    setShowVoucherField(event.target.value === 'Voucher');
   
  };



  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'firstName':
        setfirstName(value);
        break;
      case 'middleName':
        setmiddleName(value);
        break;
      case 'surname':
        setsurname(value);
        break;
        case 'IdNumber':
          setIdNumber(value);
          break;
      case 'gender':
        setgender(value);
        break;
      case 'dob':
        setdob(value);
        break;
      case 'PhoneNumber':
        setPhoneNumber(value);
        break;
       
        case 'HomeLocation':
        setHomeLocation(value);
        break;
        case 'OfficeLocation':
          setOfficeLocation(value);
          break;
      case 'email':
        setemail(value);
        break;
      case 'occupation':
        setoccupation(value);
        break;
        case 'shareInformation':
          setshareInformation(value);
        break;
        case 'subscribeCheckups':
          setsubscribeCheckups(value);
        break;
        case 'receiveMonthlyReport':
          setreceiveMonthlyReport(value);
        break;
        case 'amountpayable':
          setamountpayable(value);
        break;
        case 'BankName':
          setBankName(value);
        break;
        case 'accountNumber':
          setaccountNumber(value);
        break;
        case 'VoucherNo':
          setVoucherNo(value);
        break;
      default:
        break;
    }
  }; 



  const handlepaymentChange = (event) => {
    setpaymentMethod(event.target.value);
  };


  const handleAmountChange = (event) => {
    const inputAmount = event.target.value;
    setamountpayable(inputAmount);
     setIsAmountChecked(inputAmount);
    const multipliedValue = parseFloat(inputAmount) * 4;

    setMultipliedAmount(isNaN(multipliedValue) ? '' : multipliedValue.toFixed(0)); // Assuming you want to display the result with 2 decimal places
  };
  const handleOtherPaybleAmountChange = (event) => {
    const inputAmount = event.target.value;
    setamountpayable(inputAmount);
    const multipliedValue = parseFloat(inputAmount) * 4;

    setMultipliedAmount(isNaN(multipliedValue) ? '' : multipliedValue.toFixed(0)); // Assuming you want to display the result with 2 decimal places
 };

  
  
  const handleOtherAmountChange = (event) => {
    setIsAmountChecked(event.target.value);
    setamountpayable(0);
    setMultipliedAmount('');
    setShowAmountField(event.target.value === 'OtherAmount'); // Show extra fields if agent is selected
  };


  // Addition of dependents

  const handleNumFieldsChange = (event) => {
    const numFields = parseInt(event.target.value, 10);
    setNumExtraFields(numFields);
    // Reset extraFieldValues when the number of fields changes
    setExtraFieldValues([]);
  };
console.log("extraFieldValues",extraFieldValues);
  const handleExtraDependentChange = (index, fieldName, value) => {
    const newValues = [...extraFieldValues];  // Create a copy of the state array
    newValues[index] = {
      ...newValues[index],  // Preserve other fields in the object if any
      [fieldName]: value  // Update only the specified field
    };
    setExtraFieldValues(newValues);  // Update the state with the modified array
  };

  const handlerelationshipChange = (index, fieldName,event) => {
    const newValues = [...extraFieldValues];
    newValues[index] = {
      ...newValues[index],  // Preserve other fields in the object if any
      [fieldName]: event.target.value  // Update only the specified field
    };
    setExtraFieldValues(newValues);
  };
  const handleGender = (index, fieldName,event) => {
    const newValues = [...extraFieldValues];
    newValues[index] = {
      ...newValues[index],  // Preserve other fields in the object if any
      [fieldName]: event.target.value  // Update only the specified field
    };
    setExtraFieldValues(newValues);
  };

 const  handleGender2=(event)=>{
  setgender(event.target.value);
 } 
  let fields = [];


/* //submit */

const scrollToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: "smooth",
  });
};

  

  const telephone = localStorage.getItem('phonenumber');
  const amountTo = localStorage.getItem('amountpayable');
  const idnumber = localStorage.getItem('idno');
  const onSuccess = (data) => {
    const method = localStorage.getItem('paymentMethod');
    // setisLoading(false);
      if(method ==="VISA")
      {
         setPesapal(data.data.redirect_url);
         scrollToTop();
         setpaymentMethod("VISA");
         setPesapalOpen(true);
         setPaySucces(true);
     
      }
      if(method ==="Mpesa")
      {
         scrollToTop();
         setPhoneNumber(telephone);
         setIdNumber(idnumber);
         setamountpayable(amountTo);
         setpaymentMethod("Mpesa");
         setOpen(true);
         setMpesaSucces(true);
         

      }
      else {
      Swal.fire({
        title: '<div style="font-size:16px">THANKS FOR trusting us</div>',
        html: '<div><b>We offer more.</b></br></br>Talk to a doctor</br>Request home lab collection</br>Request home delivery of medicines</br>Home nurse services.</br></br>Just book online. <a href="https://checkupsmed.com">checkupsmed.com</a></div>',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Register New',
        cancelButtonText: 'Exit'
      }).then((result) => {
        if (result.isConfirmed) {
          
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          window.open('https://checkupsmed.com/RegistrationForm','_self');

        }
      })
    }

  
  };
  const onFailure = (err) => {
    console.log("ERR",err);
    //setisLoading(false);

    Swal.fire({
      icon: 'error',
      title: 'Our Apologies..',
      text: err.message,
    })
    //   dispatch(createCovidBookingFailure(err));
    
  };

  const processedPayload =()=> ({
  firstName,
  middleName,
  lastName: surname,
  PhoneNumber,
  idNumber:IdNumber,
  occupation,
  dob,
  email,
  gender,
  individualAccounts:extraFieldValues,
  paymentMethod,
  amountpayable,
  subscribeCheckups: false,
  receiveMonthlyReport: false,
  shareInformation: false,
  maritalStatus:"",
  packageCode:PackageName,
  bankName:BankName,
  accountNumber,
  VoucherNo,
  refferedBy:AgentCode,
  companyName:AgentName,
  county:"",
  subCounty:"",
  homeAddress:homeLocation,
  workAddress:officeLocation,
  subscriptionPackage:"",
  package:1+extraFieldValues.length,
  homeLat,
  homeLong,
  officeLat,
  officeLong,
  subscriptionPlan: "",

});
  const mutation=  usePostRequest(
      "account/SubscriptionRegistration",
      processedPayload(),
      onSuccess,
      onFailure
    ); 

  const onSubmit = () => {
  //  setisLoading(true);
      mutation.mutate();
      localStorage.setItem('amountpayable', amountpayable);
      localStorage.setItem('paymentMethod', paymentMethod);
      localStorage.setItem('idno', IdNumber);
      localStorage.setItem('phonenumber', PhoneNumber);


  };
  const actions = [];

  const renderExtraFields = () => {

    for (let i = 0; i < numExtraFields; i++) {
      fields.push(
        <Grid  container spacing={2} sx={{padding:{xs:'2.5vh',md:'4vh'}, width:{xs:'95%',md:'100%',margin:'1vh auto', backgroundColor:'#EBF5FB  ',padding:"1vh", borderRadius:'4vh', lineHeight:'1vh'}}} key={i}>
          <label style={{fontSize:'3.5vh', color:'#113356',fontFamily:'Inter Regular'}}>{`Dependent ${i + 1} ` }</label><br></br>
         <Grid item xs={12} style={{ fontSize:'3.5vh'}}> 
          <TextField
              fullWidth
               label='Surname'
               name={'lastName&{i}'}
               value={extraFieldValues[i]?.lastName|| ''}
               onChange={(e) => handleExtraDependentChange(i,'lastName', e.target.value)}
          />
          </Grid>
          <Grid item xs={12} > 
          <TextField
            fullWidth
            label="First Name"
            name={'firstName&{i}'}
            value={extraFieldValues[i]?.firstName || ''}
            onChange={(e) => handleExtraDependentChange(i,'firstName', e.target.value)}
          /> 
       </Grid>
       <Grid item xs={12} > 
           <TextField
            fullWidth
            label='Middle Name( Optional)'
            name={'middleName&{i}'}
            value={extraFieldValues[i]?.middleName || ''}
            onChange={(e) => handleExtraDependentChange(i, 'middleName', e.target.value)}
          />
         </Grid>
         <Grid item xs={12} > 
           <TextField
            fullWidth
            type="date"
            label='Date of Birth( Optional)'
            name={'dob&{i}'}
            value={extraFieldValues[i]?.dob || ''}
            onChange={(e) => handleExtraDependentChange(i, 'dob', e.target.value)}
            InputLabelProps={{
              shrink: true,
             }}
          />
         </Grid>

         <Grid item xs={12} > 
         <FormControl component="fieldset">
         <label style={{ fontSize:'3.5vh', lineHeight:'6vh'}}>Gender</label> <br></br>
              <label style={{fontSize:'3vh', fontFamily:'times new roman', lineHeight:'6vh'}}>
              <input
                type="radio"
                value="male"
                checked={extraFieldValues[i]?.gender  === 'male'}
                onChange={ (e) => handleGender(i, 'gender', e)}
                style={{fontSize:'3.5vh'}}
              />
                 Male
              </label >
              <br />
              <label style={{fontSize:'3vh', fontFamily:'times new roman', lineHeight:'6vh'}}>
                <input
                  type="radio"
                  value="female"
                  checked={extraFieldValues[i]?.gender  === 'female'}
                  onChange={(e) => handleGender(i, 'gender', e)}
                />
                Female
              </label>
       
          </FormControl>
         </Grid>
         <Grid item xs={12} > 
                 <label >Relationship</label>
                <select    value={extraFieldValues[i]?.relationship || ''} onChange={(e) => handlerelationshipChange(i,'relationship',  e)} style={{ width: '100%', padding: '0.5rem', marginTop:'2vh' , backgroundColor:'#EBF5FB ' , borderRadius:'0.5vh'}}>
                  <option value="" >Select Relationship</option>
                  <option value="husband" >Husband</option>
                  <option value="wife">Wife</option>
                  <option value="mother">Mother</option>
                  <option value="father">Father</option>
                  <option value="son">Son</option>
                  <option value="daughter">Daughter</option>
                  <option value="guardian">Guardian</option>
                  <option value="sister">Sister</option>
                  <option value="brother">Brother</option>
                  <option value="aunt">Aunt</option>
                  <option value="uncle">Uncle</option>
                </select>
         </Grid>

         <Grid item xs={12}> 
           <TextField
            fullWidth
            label='ID/Passport No( Optional)'
            name={'idno&{i}'}
            value={extraFieldValues[i]?.idno || ''}
            onChange={(e) => handleExtraDependentChange(i, 'idno', e.target.value)}
           
          />
         </Grid>
         <Grid item xs={12} > 
           <TextField
            fullWidth
            label='Phone Number( Optional)'
            name={'phonenumber&{i}'}
            value={extraFieldValues[i]?.phonenumber || ''}
            onChange={(e) => handleExtraDependentChange(i, 'phonenumber', e.target.value)}
          />
         </Grid>
       
        </Grid>
      );
    }
    return fields;
  };
   // Addition of dependents


 
  return (
    <div>
      
      <Navbar/>

      
    <Box sx={{padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'},borderRadius:'4vh',
              margin: '80px auto',
              backgroundImage: `url('${joinusbg}')`,
              backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
             }}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{textAlign:'center'}}>
           
                <Typography variant="h6"sx={{width: 'auto', color: '#113356',fontFamily: 'Inter Regular ',fontSize:{xs:'2.5vh',md:'3.5vh'}, marginTop: '20px',lineHeight:'110%',marginLeft:'5px', textAlign:'center',fontWeight:'600'}}>
                How COVA works?
                </Typography>

                <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#113356',fontWeight:'550'}}>
                When you invest, you get more money back in benefits                
                </Typography >
                <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#113356',fontWeight:'550'}}>
                  Your benefits are split into different groups:
                </Typography >
                <Grid style={{backgroundColor:'#BFC9CA', borderRadius:'4vh', padding:'3vh', margin:'2vh'}}>
                <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left', color:'#113356', fontWeight:'600'}}>
                CLASSICare Membership
                </Typography >
                <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#113356',fontWeight:'550'}}>
                  - GP visits <br></br>
                  - Nurse visits<br></br>
                  - Lab tests<br></br>
                  - X-ray<br></br>
                  - Ultrasound<br></br>
                  - ECG

                 </Typography >
                 </Grid>
                 <Grid style={{backgroundColor:'#EAECEE ', borderRadius:'4vh', padding:'3vh', margin:'2vh'}}>
                 <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#113356',fontWeight:'600'}}>             
                  SMARTCare Membership
                 </Typography >
                 <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#113356',fontWeight:'550'}}>
                 - GP visits <br></br>
                  - Nurse visits<br></br>
                  - Lab tests<br></br>
                  - X-ray<br></br>
                  - Ultrasound<br></br>
                  - ECG <br></br>
                  - Pharmacy
                 </Typography >
                 </Grid>
                 <Grid style={{backgroundColor:'#D5D8DC', borderRadius:'4vh', padding:'3vh', margin:'2vh'}}>
                 <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#113356',fontWeight:'600'}}>
                 DELUXE Care Membership
                 </Typography >
                 <Typography sx={{margin:'32px 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'3.5vh'}, lineHeight: '180%',marginLeft:'5px',marginRight:'50px', textAlign:'left',color:'#113356',fontWeight:'550'}}>
                 - GP visits <br></br>
                  - Nurse visits<br></br>
                  - Lab tests<br></br>
                  - X-ray<br></br>
                  - Ultrasound<br></br>
                  - ECG <br></br>
                  - Pharmacy<br></br>
                  - Specialist care (like for pregnant moms, kids, heart, diabetes, skin, pain, wounds, and mental health)

                 </Typography >
                 </Grid>
             
          </Grid>

          {PaySucces ===true ? (
        <>
        <Root>
          {/* <Paper2> */}
          <GenericDialog
                title='INITIATE VISA PAYMENT'
                showDialog={pesapalOpen}
                onCloseDialog={() => {
                  setPesapalOpen(false);
                  window.open('https://checkupsmed.com/RegistrationForm', '_self');
                }}
                actions={actions}
                isLoading={mutation.isLoading}
                width="sm"
                height={400}
                verticalAlign="flex-start"
              >
                <Grid container spacing={1}>
                {/* <Grid item md={2} lg={2} xl={3}></Grid> */}
                {/* <Grid item md={12} xs={12} xl={12}>                     */}
                <Grid item xs={12} lg={12}>
              <Iframe url={pesapal}
                width="100%"
                height="600px"
                id="myId"
                target={"_blank"}
                className="myClassname"                                    
                position="relative"/>                                                
            </Grid>
          
               </Grid>
               </GenericDialog>
               {/* <Grid item md={2} lg={2} xl={3}></Grid> */}
             {/* </Grid> */}
             {/* </Paper2> */}
             </Root>
             </>                               
        ) : MpesaSucces ===true ? (
            <>
            <Root>
            <Paper2>
                    <Grid container spacing={1}>
                    <Grid item md={2} lg={2} xl={3}></Grid>
                    <Grid item md={8} xs={12} xl={6}>                    
                    <Grid item xs={12} lg={12}>
                    <PaymentModal
                        open={open}
                        setOpen={setOpen}
                        subscriptionAmount={amountpayable}
                        telephone={PhoneNumber}
                        IDNumber={idnumber}
                        FirstName={firstName}
                    />                                               
                </Grid>
                  </Grid>
                  <Grid item md={2} lg={2} xl={3}></Grid>
                </Grid>
                </Paper2>
                </Root>
                </>                               
        ): (
            <> 
          <Grid item xs={12} md={6}  sx={{padding:'0.1vh', marginTop:{xs:'0.1vh',md:'0vh'}}}>
          {/* <form> */}
            <Grid  container spacing={2} sx={{padding:{xs:'2.5vh',md:'4vh'}, width:{xs:'95%',md:'100%',margin:'1vh auto', backgroundColor:'#181C6C',padding:"1vh", borderRadius:'4vh', fontWeight: 400,}}}>
              <Grid item xs={12} style={{backgroundColor:'#FFFFFF', borderRadius:'2.5vh', marginTop:'1vh'}}>
              <label style={{fontSize:'3.5vh', color:'#113356',fontFamily:'Inter Regular', fontWeight: '550'}}> Please select a type of subscription below :</label><br></br>
              <RadioGroup aria-label="options" name="options" value={PackageName} onChange={handlepackageChange}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button style={{padding:'2vh'}}>
                        <FormControlLabel value="3" control={<Radio />} label="Deluxe Care"/>
                        </Button>
                        <Button  style={{padding:'2vh'}}>
                        <FormControlLabel value="2" control={<Radio />} label="Smartcare" />
                        </Button>
                        <Button  style={{padding:'3vh'}}>
                        <FormControlLabel value="1" control={<Radio />} label="Classicare" />
                        </Button>
                      </Grid>
                    </Grid>
                  </RadioGroup>
              </Grid>

 {/*  ////////////// */}
              
 

{/* main members */}

<Grid item xs={12} style={{padding:'2vh',backgroundColor:'#FFFFFF', borderRadius:'2.5vh', marginTop:'1vh', lineHeight:'12vh', fontSize:'3.5vh'}}>
  <labe style={{ fontSize:'3.5vh',color:'#113356',fontFamily:'Inter Regular'}}>Enter main member details</labe> 
          <TextField
              fullWidth
               required
               label='Surname'
               name='surname'   
               value={surname}
               onChange={handleChange}
          />
        
          <TextField
            fullWidth
            required
            label="First Name"
            name='firstName'
            value={firstName}
            onChange={handleChange}
          /> 
      
           <TextField
            fullWidth
            label='Middle Name( Optional)'
            name='middleName'
            value={middleName}
            onChange={handleChange}

          />
       
           <TextField
            fullWidth
            required
            label='ID/Passport No'
            name='IdNumber'
            value={IdNumber}
            onChange={handleChange}
          />
         
         <FormControl component="fieldset">
          <FormLabel component="legend" >Gender</FormLabel>
          <Box display="flex">
            <RadioGroup
              aria-label="gender"
              name="gender"
              value={gender ? gender : ''}
              onChange={handleGender2}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
              />
            </RadioGroup>
          </Box>
        </FormControl>

          {/* <label >Gender</label> 
              <label >
              <input
                type="radio"
                value="male"
                checked={gender === 'male'}
                onChange={handleGender2}
              
              />
                 Male
              </label >
              <br />
              <label >
                <input
                  type="radio"
                  value="female"
                  checked={gender === 'female'}
                  onChange={handleGender2}
                />
                Female
              </label> */}
       
           <TextField
            fullWidth
            required
            label='Phone Number'
            name='PhoneNumber'
            value={PhoneNumber}
            onChange={handleChange}
            
          />
         
           <TextField
            fullWidth
            label='Date of Birth'
            type='date'
            variant="outlined"
            required
            name='dob'
            value={dob}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
             }}
          />
        
           <TextField
            fullWidth
            type='email'
            label='Email Address'
            name='email'
            value={email}
            onChange={handleChange}
          />
         
           <TextField
            fullWidth
            label='Occupation'
            name='occupation'
            value={occupation}
            onChange={handleChange}
          />    

        {/* main member */}

        {/* Location */}

        <Grid container spacing={0}>
              <Grid item xs={12} sm={12}>
                {/* <FormLabel component="legend" style={{fontWeight: 700, color: "#449afe", fontSize: "1.1rem", fontFamily: "ChronicaPro, sans-serif", marginBottom: '10px'}}>
                  Office
                </FormLabel> */}
                <LocationOffice
                  location={officeLocation}
                  setLocation={setofficeLocation}
                  label="Office Location"
                  setLatitude={setOfficeLat}
                  setLongitude={setOfficeLong}
                
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <FormLabel component="legend" style={{fontWeight: 700, color: "#449afe", fontSize: "1.1rem", fontFamily: "ChronicaPro, sans-serif", marginBottom: '10px'}}>
                  Home
                </FormLabel> */}
                <LocationHome
                  location={homeLocation}
                  setLocation={setHomeLocation}
                  label="Home Location"
                  setLatitude={setHomeLat}
                  setLongitude={setHomeLong}
                
                />
              </Grid>
            </Grid>
        

 {/*  ////////////// */}
 <Grid item xs={12} style={{backgroundColor:'#FFFFFF', borderRadius:'2.5vh', fontSize:'3.5vh'}}>
          <FormControl component="fieldset">
            <label >Do you want to add dependents?</label>
           
        <RadioGroup aria-label="options" name="options"  value={Dependents} onChange={handleDependentsChange} >
          <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="No" control={<Radio />} label="No" />
        </RadioGroup>
          </FormControl>
        </Grid>
        
        {ShowDependents && (
          <>
          <Grid>
          <TextField
            fullWidth
            type="number"
            label="Enter Number of Dependents"
            value={numExtraFields}
            onChange={handleNumFieldsChange}
            style={{lineHeight:'6vh'}}
          />
        </Grid>
        {renderExtraFields()}

          </>   
        )}

 {/* dependents field */}
 
        
          
        </Grid>
    

  {/* dependents field */}
  <Grid item xs={12} style={{padding:'2vh',lineHeight:'6vh',fontSize:'3vh',backgroundColor:'#FFFFFF', borderRadius:'2.5vh',marginTop:'1vh'}}>
          <label style={{ fontSize:'3.5vh',color:'#113356', fontFamily:'Inter Regular'}}>Select amount to pay</label> <br></br>
              <label>
              <input
                type="radio"
                value="500"
                checked={isAmountChecked=== '500'}
                onChange={handleAmountChange}
              />
                 Pay ksh 500 to unlock 2000 benefits
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="1000"
                  checked={isAmountChecked === '1000'}
                  onChange={handleAmountChange}
                />
               Pay ksh 1000 to unlock 4000 benefits
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  name='medicalcredit'
                  value="1500"
                  checked={isAmountChecked=== '1500'}
                  onChange={handleAmountChange}
                />
              Pay ksh 1500 to unlock 6000 benefits
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="2000"
                  checked={isAmountChecked === '2000'}
                  onChange={handleAmountChange}
                />
                 Pay ksh 2000 to unlock 8000 benefits
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="2500"
                  checked={isAmountChecked === '2500'}
                  onChange={handleAmountChange}
                />
                 
                 Pay ksh 2500 to unlock 10000 benefits
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="OtherAmount"
                  checked={isAmountChecked === 'OtherAmount'}
                  onChange={handleOtherAmountChange}
                />
                 Other
              </label>
         </Grid>

         {isAmountChecked === 'OtherAmount' ? (
          <>
            <Grid item xs={12} style={{backgroundColor:'#FFFFFF', borderRadius:'2.5vh', lineHeight:'8vh'}}>
            <TextField
        fullWidth
        label="Enter Amount"
        value={amountpayable}
        onChange={handleOtherPaybleAmountChange}
      />
      <div style={{ marginTop: '10px' }}>
        {multipliedAmount && (
          <div style={{ fontFamily:'Inter Regular', alignItems:'center', fontSize:'3.5vh', color:'#113356'}}>
            Pay ksh {amountpayable} to unlock  {multipliedAmount} benefits
          </div>
        )}
      </div>
            </Grid>
          </>   
        ):(<></>)}



      <Grid item xs={12} style={{padding:'2vh',lineHeight:'6vh',fontSize:'3vh',backgroundColor:'#FFFFFF', borderRadius:'2.5vh',marginTop:'1vh'}}>
          <label style={{ fontSize:'3.5vh',color:'#113356', fontFamily:'Inter Regular'}}> Payment Method Options</label> <br></br>
              <label>
              <input
                type="radio"
                value="Mpesa"
                checked={paymentMethod === 'Mpesa'}
                onChange={handlepaymentChange}
              />
                 Mpesa Paybill
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  value="VISA"
                  checked={paymentMethod === 'VISA'}
                  onChange={handlepaymentChange}
                />
                Visa/Mastercard
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  name='medicalcredit'
                  value="medicalcredit"
                  checked={paymentMethod === 'medicalcredit'}
                  onChange={handlepaymentChange}
                />
                Medical Credit
              </label>

              {paymentMethod === 'medicalcredit' ? (
          <>
            <Grid item xs={12} style={{backgroundColor:'#FFFFFF', borderRadius:'2.5vh', lineHeight:'12vh'}}>
            <TextField
              fullWidth
              label="Bank Name"
              name="BankName"
              value={BankName}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Account Number"
              name="accountNumber"
              value={accountNumber}
              onChange={handleChange}
            />
      
      
            </Grid>
          </>   
        ) : (<></>)}


              <br />
              <label>
                <input
                  type="radio"
                  value="voucher"
                  checked={paymentMethod === 'voucher'}
                  onChange={handlepaymentChange}
                />
                Voucher
              </label>
               
              {paymentMethod === 'voucher' ? (
          <>
            <Grid item xs={12} style={{backgroundColor:'#FFFFFF', borderRadius:'2.5vh', lineHeight:'12vh'}}>
            <TextField
              fullWidth
              label="Enter Voucher No"
              name="VoucherNo"
              value={VoucherNo}
              onChange={handleChange}
            />
            </Grid>
          </>   
        ):(<></>)}


         </Grid>
           

         <Grid item xs={12} style={{color:'#113356',fontFamily:'Inter Regular',fontSize:'3.5vh', fontWeight: '550',backgroundColor:'#FFFFFF', borderRadius:'2.5vh', marginTop:'1vh'}}>
      <FormControl component="fieldset">
            <label >How did you hear about us?</label>
           
        <RadioGroup aria-label="options" name="options"  value={AgentDetails} onChange={handleReferalChange} >
          <FormControlLabel value="self" control={<Radio />} label="Self" />
          <FormControlLabel value="agent" control={<Radio />} label="Through the Agent" />
        </RadioGroup>
          </FormControl>
        </Grid>
       

        {showAgentDetails && (
          <>
            <Grid item xs={12} style={{backgroundColor:'#FFFFFF', borderRadius:'2.5vh', lineHeight:'8vh'}}>
              <TextField
            
                fullWidth
                label="Enter Agent Code" 
                name="AgentCode"
                value={AgentCode}
                onChange={(e)=>setAgentCode(e.target.value)}
              />
          
              <TextField
                fullWidth
                label="Enter Agent Company Name"
                name="AgentName"
                value={AgentName}
                onChange={(e)=>setAgentName(e.target.value)}
              />
            </Grid>
          </>   
        )}




                  <Grid item xs={12} style={{padding:'2vh',lineHeight:'6vh',fontSize:'3.5vh',backgroundColor:'#FFFFFF', borderRadius:'2.5vh',marginTop:'1vh'}}> 
                  <FormControl component="fieldset" style={{fontSize:'3.5vh'}}>
                      <label style={{fontSize:'3.5vh', color:'#113356',fontFamily:'Inter Regular'}}>Disclaimer</label>
                      <FormGroup >
                        <FormControlLabel
                          value={subscribeCheckups}
                          control={<Checkbox  />}
                          onChange={handleChange}
                          label="Do you want to subscribe to checkups information"
                        />
                        <FormControlLabel
                        value={receiveMonthlyReport}
                          control={<Checkbox/>}
                          onChange={handleChange}
                          label="Do you want to receive monthly report through email"
                        />
                        <FormControlLabel
                        value={shareInformation}
                          control={<Checkbox/>}
                          onChange={handleChange}
                          label="Can we share your information with other medical doctors"
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid> 
              <Grid item xs={12} style={{ borderRadius:'2.5vh', marginTop:'1vh', fontSize:'3.5vh', fontFamily:'Inter Regular', textAlign:'center'}}>
                <Button variant="contained" color="primary" type="submit" style={{fontSize:'3.5vh', fontFamily:'Inter Regular', textAlign:'center', borderRadius:'2.5vh', width:'50vh'}}
                
                onClick={(e) => {
                  handleSubmit(() => {
                      handleSubmit(onSubmit)(e);
                  })(e);
              }}
               //disabled={(checked == false && isValid==false) ? true : false}
                >
                   {mutation.isLoading ? <LoadingSpinner /> : <></>}
                  Submit
                </Button>
              </Grid>
            </Grid>
    {/* </form> */}
          </Grid>
          </>
    )}
      </Grid>
    </Box>
 
    <Footer/>

    </div>
  );
};

export default RegistrationForm;
import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import healthbg from "../HomeAssets/Rider.jpg";

import emailicon from "../HomeAssets/EmailBlackIcon.webp";
import linkedinicon from "../HomeAssets/LinkedinBlackIcon.webp";
import phoneicon from "../HomeAssets/PhoneBlackIcon.webp";
import WhatsAppicon from "../HomeAssets/WhatsAppBlackIcon.webp";

const GetInTouchContact = () => {
  return (
    <Box sx={{padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, backgroundColor: '#ffffff',  margin: '80px auto',borderRadius:'4vh'  }}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{alignItems:'center'}}>
             <img src={healthbg } alt="For Employers" style={{width:"90%", height:'auto', borderRadius:'5vh'}} />
          </Grid>
         <Grid item xs={12} md={6} sx={{color:'#113356', paddingLeft:'8vh',paddingTop:{xs:'4vh',md:'20vh'}}}>
            <Typography>
              <p style={{fontFamily:'Inter Display', fontWeight:'400', fontSize:'6vh', lineHeight:'6vh', color:'#113356',marginBottom:'1.5vh',paddingTop:'10vh'}}>Ready to start using COVA ?</p>
            </Typography>
            <Typography>
              <p style={{fontFamily:'Inter', fontWeight:'400', fontSize:'3.5vh',lineHeight:'6vh',marginBottom:'7vh'}}>Get in contact with us via the following channels.</p>
            </Typography>
            <Typography  style={{margin:'4px 0',fontFamily:'Inter',fontSize:'3.5vh', lineHeight: '5vh',color:'#113356'}}>
              <p> < a href="https://wa.me/+25411050290" target="blank"><img src={WhatsAppicon} alt='' style={{marginRight:'3vh',lineHeight:'6vh'}}/></a> <p style={{marginLeft:'10vh', marginBottom:'1vh',marginTop:'-11vh',fontWeight:'600'}}>Whatsapp</p></p>
              <p style={{marginLeft:'10vh', marginBottom:'5vh'}}>(+254) (0) 111 050 290</p>
              <p><img src={phoneicon} alt='' style={{marginRight:'3vh'}}/> <p style={{marginLeft:'10vh', marginBottom:'1vh',marginTop:'-11vh',fontWeight:'600'}}>Mobile phone</p></p>
              <p style={{marginLeft:'10vh', marginBottom:'5vh'}}>(+254) (0) 111 050 290</p>
              <p> <a href='mailto:info@checkUpsmed.com' target="blank"><img src={emailicon} alt='' style={{marginRight:'3vh'}}/></a> <p style={{marginLeft:'10vh', marginBottom:'1vh',marginTop:'-11vh',fontWeight:'600'}}>Email </p></p>
              <p style={{marginLeft:'10vh', marginBottom:'5vh'}}>info@checkupsmed.com</p>
              <p> <a href='https://www.linkedin.com/company/checkups-medical/mycompany/' target="blank"><img src={linkedinicon} alt='' style={{marginRight:'3vh'}}/> </a><p style={{marginLeft:'10vh', marginBottom:'1vh',marginTop:'-11vh',fontWeight:'600'}}>Linkedin </p></p>
              <p style={{marginLeft:'10vh', marginBottom:'5vh'}}>Checkups COVA</p>
            </Typography>
         </Grid>
      </Grid>
    </Box>
  );
};

export default GetInTouchContact;
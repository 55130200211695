import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Typography,
  } from '@mui/material';
  import { styled } from '@mui/system';
  import { Close } from '@mui/icons-material';
  import { uniqueId } from 'lodash';
  import React from 'react';
  
  const Root = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    width: '100%',
  }));
  
  const Header = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  }));
  
  const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    textTransform: 'capitalize',
  }));
  
  const CloseButton = styled(IconButton)(({ theme }) => ({
    padding: 0,
  }));
  
  const Content = styled(DialogContent)(({ theme }) => ({
    padding: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
  }));
  
  const SubmitButton = styled(Button)(({ theme }) => ({
    minWidth: 165,
    width: 'fit-content',
  }));
  
  const GenericDialog = ({
    showDialog,
    onCloseDialog,
    children,
    actions,
    title,
    isLoading,
    width = 'md',
    height = 900,
    verticalAlign = 'flex-start',
  }) => {
    return (
      <Dialog
        sx={{ padding: 1, width: '100%' }}
        fullWidth
        open={showDialog}
        aria-labelledby="dialog-modal"
        maxWidth={width}
      >
        <Header id="dialog-modal">
          <Title>{title}</Title>
          <CloseButton onClick={onCloseDialog}>
            <Close />
          </CloseButton>
        </Header>
        <Content
          dividers
          sx={{
            minHeight: height,
            minWidth: width,
            justifyContent: verticalAlign,
          }}
        >
          {children}
        </Content>
        <DialogActions>
          {actions.map(({ label, onClick, primary }) => (
            <SubmitButton
              key={uniqueId()}
              color={primary ? 'secondary' : 'inherit'}
              variant={primary ? 'contained' : undefined}
              onClick={onClick}
              disabled={isLoading}
            >
              {label}
            </SubmitButton>
          ))}
        </DialogActions>
      </Dialog>
    );
  };
  
  export default GenericDialog;
  
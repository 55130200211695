import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import smes from "../HomeAssets/for SMEs Image.png";
import whitearrow from "../HomeAssets/white-arrow.png";
import { createTheme, responsiveFontSizes,ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';



let theme = createTheme();
theme = responsiveFontSizes(theme);

const ForSmes = () => {
  return (
    <Box sx={{ padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'}, backgroundColor: '#f9f9f9', margin: '80px auto',borderRadius:'4vh'  }}>
      <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{textAlign:'center'}}>
          <img src={smes} alt="For Employers" style={{ width: '90%', height:'auto' }} />
          </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom sx={{backgroundColor:'rgba(202,89,134,0.4)',padding:'1vh', borderRadius:'2.5vh', fontFamily:'Inter Regular',fontSize:{xs:'2vh',md:'4vh'}, width:{xs:'30vh',md:'55vh'}, marginTop:'2.5vh', color:'#113356',margin:'2.5vh'}}>
          FOR SMES AND FAMILIES
          </Typography>
          <Typography variant="h6"
          sx={{width: 'auto',
            margin:'4vh 2.5vh',
            fontFamily: 'Inter Display',
            fontSize: {xs:'4vh',md:'6vh'}, 
            marginTop: '20px',
            color:'#113356'}}>
            Plan ahead for medical bills and unexpected healthcare expenses
          </Typography>
          <Typography  
          sx={{margin:'4vh 2.5vh',
             fontFamily:'Inter Regular',
             fontSize: {xs:'2.5vh',md:'3.5vh'}, 
             lineHeight: '2.5rem',
          
           color:'#113356'}}>
            Don't wait until illness strikes your family or threatens your  
            business cashflow. Plan ahead for medical bills & unexpected 
            healthcare expenses for your family members, loved ones 
            and employees with CheckUps COVA.
          </Typography>
          <Button variant="contained" color="primary" sx={{backgroundColor:'#113356',fontFamily:'Inter', borderRadius:'1vh', width:'40%', fontSize:{xs:'2vh',md:'3vh'},padding:'1.5vh 1.5vh',marginTop:'1vh',textTransform:"none",margin:' 2.5vh '}}>
          <Link to="/RegistrationForm" style={{color:'white', textDecorationLine:'none'}}> 
        Get Started
        <img src={whitearrow} alt='' style={{ width: '2.5vh', marginLeft: '1vh' }} />
         </Link> 
          </Button>
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default ForSmes;
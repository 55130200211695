import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';

import howtoenrollvideo from "../HomeAssets/enrolling.mp4";
import topvideo from "../HomeAssets/top up.mp4";
import checkupsvideo from "../HomeAssets/CheckUps Motion Video 2.mp4";



const HowToUseCova = () => {
  return (
    <Box  sx={{ padding:{xs:2,md:4}, width:{xs:'96%',md:'90%'},  margin: '80px auto', backgroundColor:'#F8FAFC',borderRadius:'4vh'}}>
      <Typography variant="h4" gutterBottom style={{fontSize:'4vh',fontFamily: 'Inter Regular',color:'#113356'}}>
        How to use COVA
      </Typography>
      <Grid container spacing={4} justifyContent="center" style={{height:'auto'}} >
        <Grid item xs={12} sm={6} md={4} style={{paddingTop:'10vh'}}>
          <Card sx={{ }} style={{borderColor:'#113356', borderStyle:'groove', borderRadius:'2vh', borderWidth:'0.3vh'}}>
            <CardContent>
            <Typography>
              <video controls style={{width:'100%', height:'50vh'}}>
                <source src={checkupsvideo} type='video/mp4'/>
             </video>
            </Typography>
              <Typography variant="h6" style={{fontFamily: 'Inter Display', fontSize:'3vh',color:'#113356'}}>
                How to request for services on COVA
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{paddingTop:'10vh'}}>
          <Card sx={{ }} style={{borderColor:'#113356', borderStyle:'groove', borderRadius:'2vh', borderWidth:'0.3vh'}}>
            <CardContent>
              <Typography>
              <video controls style={{width:'100%', height:'50vh'}}>
                <source src={topvideo} type='video/mp4'/>
             </video>
            </Typography>
              <Typography variant="h6" style={{fontFamily: 'Inter Display', fontSize:'3vh',color:'#113356'}}>
              How to top up your COVA credit
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={4} style={{paddingTop:'10vh'}}>
          <Card sx={{  }} style={{borderColor:'#113356', borderStyle:'groove', borderRadius:'2vh', borderWidth:'0.3vh'}}>
            <CardContent>
              <Typography>
              <video controls style={{width:'100%', height:'50vh'}}>
                <source src={howtoenrollvideo} type='video/mp4'/>
             </video>
            </Typography>
              <Typography variant="h6" style={{fontFamily: 'Inter Display', fontSize:'3vh',color:'#113356'}}>
             How to enroll into Checkups COVA
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowToUseCova;
import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import Footer from './Footer';
import Navbar from './Navbar';


const SecurityPolicy = () => {
  return (

    <div className="section-container">
        <Navbar/>
  
    <Box  sx={{  padding:{xs:2,md:4}, width:{xs:'96%',md:'90%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
     
      
      
      <Grid container spacing={4} justifyContent="center" style={{height:'auto',marginTop:'-20px'}} >
        
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: '#FCFAF8' }} style={{paddingTop:'0vh'}}>
            <CardContent >
               <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
               SECURITY POLICY FOR CHECKUPS MEDICAL
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Last updated: June 5, 2024
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              1. Purpose
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The purpose of this Security Policy is to safeguard information belonging to Checkups Medical, its holding, parent, sister, or subsidiary 
              companies (collectively referred to as "the Company") within a secure environment. This Policy informs the Company's staff and other
               individuals entitled to use the Company's facilities of the principles governing the holding, use, and disposal of information.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              2. Goals
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The Company aims to:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Protect information against unauthorized access or misuse.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Secure the confidentiality of information.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Maintain the integrity of information.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Ensure the availability of information and information systems for service delivery.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Maintain business continuity planning processes.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Comply with regulatory, contractual, and legal requirements, including GDPR, CCPA, CPRA, and CalOPPA.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Maintain physical, logical, environmental, and communications security.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              3. Consequences of Policy Infringement
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Infringement of this Policy may result in disciplinary action or criminal prosecution. When information is no longer of use, it is
               disposed of in a suitable manner.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              4. Scope of Information
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              This Policy relates to:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Electronic information systems (software, computers, and peripherals) owned by the Company, whether deployed or accessed on or off site.  
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - The Company's computer network used either directly or indirectly.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Hardware, software, and data owned by the Company.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Paper-based materials.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Electronic recording devices (video, audio, CCTV systems).
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              5. User Responsibilities
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The Company requires all users to exercise a duty of care in relation to the operation and use of its information systems.
               Authorized users must be formally authorized by appointment as a member of staff or contractor. Authorized users will pay 
               due care and attention to protect the Company's information in their personal possession.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              6. Acceptable Use of Information Systems
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Use of the Company's information systems by authorized users will be lawful, honest, and decent, and shall have regard to the 
              rights and sensitivities of other people.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              7. Responsibilities of Information System Directors
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Information System Directors are responsible for ensuring that:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Systems are adequately protected from unauthorized access.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Systems are secured against theft and damage to a level that is cost-effective.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Adequate steps are taken to ensure the availability of the information system.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Electronic data can be recovered in the event of loss of the primary source.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Data is maintained with a high degree of accuracy.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Systems are used for their intended purpose and procedures are in place to rectify discovered or notified misuse.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Electronic access logs are only retained for a justifiable period.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              - Third parties entrusted with the Company's data understand their responsibilities.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              8. Personal Information and Privacy
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Authorized users of information systems are not given rights of privacy in relation to their use of the Company's information systems. Duly authorized officers of 
              the Company may access or monitor personal data contained in any Company information system. The Company will take legal action to 
              ensure that its information systems are not used by unauthorized persons.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              9. Compliance with Privacy Laws and Regulations
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The Company is committed to complying with applicable privacy laws and regulations, including GDPR, CCPA, CPRA, and CalOPPA. Personal information collected, used,
               or disclosed by the Company will be handled in accordance with the provisions of these laws. Data subjects have the right to request access to,
                rectification, or erasure of their personal data, and may object to or restrict certain types of processing, as provided by applicable laws.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              10. Contact Information
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              If you have any questions or concerns about this Security Policy, please contact us:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              By email: < a href='mailto:info@checkUpsmed.com' target="blank">info@checkupsmed.com</a>
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              On our page: <a href='https://checkupsmed.com/ContactUs' target='blank'>www.checkupsmed.com/contactUs</a>
              </Typography>
             {/*  <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'2.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', backgroundColor:'yellow'}}>
              By mail: [Your Mailing Address]
              </Typography> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    <Footer/>
    </div>
  );
};

export default SecurityPolicy;
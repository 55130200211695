import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import bgimage from "../HomeAssets/join us background.png";
import { createTheme, responsiveFontSizes,ThemeProvider } from '@mui/material/styles';
let theme = createTheme();
theme = responsiveFontSizes(theme);

const MainHeroResources = () => {
  return (
    <ThemeProvider theme={theme}>
   <Box sx={{ padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, margin: '4vh auto' }}>
  <Grid container spacing={2} style={{ marginLeft: '0.5vh' }}>
    <Grid item xs={12} md={6}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Inter Regular',
          fontSize: { xs: '4vh', md: '7vh' },
          fontWeight: 400,
          marginTop: '1vh',
          color: '#113356',
          lineHeight: { xs: '6vh', md: '7vh' },
        }}
      >
        Resources
      </Typography>
      
      <Typography
        sx={{
          margin: '2vw 0',
          fontFamily: 'Inter Regular',
          fontSize: { xs: '2vh', md: '3.5vh' },
          fontWeight: 400,
          lineHeight: { xs: '4vh', md: '6vh' },
          color: '#113356',
        }}
      >
       Access helpful articles, resources and <br></br>
       case studies prepared by our team.
      </Typography>
   
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        textAlign: 'center',
        minHeight:{xs:'10vh',md:'10vh'},
        backgroundImage: `url('${bgimage}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
    </Grid>
  </Grid>
  
</Box>
    </ThemeProvider>
  );
};

export default MainHeroResources;
import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import icon from "../HomeAssets/icon.png";
import getintouchimage from "../HomeAssets/learn more Image.png";
import whitearrow from "../HomeAssets/white-arrow.png";


const GetInTouch = () => {
  return (
    <Box sx={{  padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'}, backgroundColor: 'rgba(252,250,248,1)',  margin: '80px auto',borderRadius:'4vh'  }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom style={{backgroundColor:'rgba(202,89,134,0.4)',padding:'1vh', width:'40vh', borderRadius:'3vh', fontFamily:'Inter Regular', fontSize:'4vh', marginTop:'2.5vh',color:'#113356',marginLeft:'2vh', borderStyle:'groove'}}>
          GET IN TOUCH
          </Typography>
          <Typography variant="h6" 
          sx={{width: 'auto',color: '#113356',fontFamily: 'Inter Display',fontSize: {xs:'4.5vh',md:'6vh'}, marginTop: '2.5vh',marginLeft:'2vh'}}>
            Want to learn more about CheckUps COVA?
          </Typography>
          <Typography  sx={{margin:'3.5vh 0', color: '#113356',fontFamily:'Inter Regular',fontSize:{xs:'2.2vh',md:'4vh'}, lineHeight: '7vh', marginLeft:'2vh'}}>
              <p><img src={icon} alt=""style={{marginRight:'1.5vh'}}/>learn how our solutions can benefit you and your group</p>
              <p><img src={icon} alt=""style={{marginRight:'1.5vh'}}/>Discover how our platform can help you save time & money</p>
              <p><img src={icon} alt=""style={{marginRight:'1.5vh'}}/>Add members to your group and unlock 4X benefits for all</p>
              <p><img src={icon} alt=""style={{marginRight:'1.5vh'}}/>Access a wide range of services and support</p>
          </Typography>
          <Button variant="contained" color="primary" sx={{backgroundColor:'#113356',fontFamily:'Inter', borderRadius:'1.5vh',width:'55%', fontSize:{xs:'2vh',md:'3.5vh'},padding:'1.5vh 1.5vh',marginTop:'1vh',textTransform:"none",marginLeft:'3vh'}}>
          Book a tea-time
            <img src={whitearrow} alt='' style={{width: '3.5vh',marginleft: '1vh'}}/>
          </Button>
        </Grid>
        <Grid item xs={12} md={6} style={{textAlign:'center'}}>
          <img src={getintouchimage} alt="For Employers" style={{width: '90%', height:'auto' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GetInTouch;
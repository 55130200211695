import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";
import JoinUs from "./JoinUs.jsx";
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import HowToUseCova from "./HowToUseCova.jsx";
import CaseStudies from "./CaseStudies.jsx";
import MainHeroResources from "./MainHeroResources.jsx";
import IbmBlog from "./IbmBlog.jsx";




function About() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <Navbar/>

      <div onClick={scrollToTop} className={`scroll-up ${goUp ? "show-scroll" : ""}`}>
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
      <MainHeroResources/>
       <HowToUseCova/>
       <CaseStudies/>
       <JoinUs/>
   
      <Footer/>
    </div>
  );
}

export default About;

import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent  } from '@mui/material';
import bgimage from "../HomeAssets/join us background.png";
import aboutimg from "../HomeAssets/bus.jpg";
import { createTheme, responsiveFontSizes,ThemeProvider } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const MainHeroAbout = () => {
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, margin: '4vh auto' }}>
  <Grid container spacing={2} style={{ marginLeft: '0.5vh' }}>
    <Grid item xs={12} md={6} style={{marginBottom:'-15vh'}}>
      <Typography
        variant="h6"
        sx={{
          fontFamily: 'Inter Regular',
          fontSize: { xs: '4vh', md: '7vh' },
          fontWeight: 400,
          marginTop: '1vh',
          color: '#113356',
          lineHeight: { xs: '6vh', md: '7vh' },
        }}
      >
        About Us
      </Typography>
      <Typography
        sx={{
          margin: '2vh 0.5vh',
          fontFamily: 'Inter Regular',
          fontSize: { xs: '2vh', md: '3.5vh' },
          fontWeight: 400,
          lineHeight: { xs: '4vh', md: '5.5vh' },
          color: '#113356',
        }}
      >
       At CheckUps, we set out to establish a network of stateof-the-art Urgent Care Centers in Kenya, driven by the
       belief that everyone deserves access to quality healthcare
       without worrying about the financial burden.
      </Typography>
      <Typography
        sx={{
          margin: '2vw 0',
          fontFamily: 'Inter Regular',
          fontSize: { xs: '2vh', md: '3.5vh' },
          fontWeight: 400,
          lineHeight: { xs: '4vh', md: '5.5vh' },
          color: '#113356',
        }}
      >
       Our centers are equipped with well-stocked laboratories, pharmacies,
       comfortable consultation rooms, welcoming waiting areas, advanced
       imaging departments (X-ray & Ultrasound), and ECG facilities.
      </Typography>
   
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        textAlign: 'center',
        minHeight: '50vh',
        backgroundImage: `url('${bgimage}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
         
    </Grid>
  </Grid>
  
</Box>
    <Box  sx={{ padding: 1, width:'95%',  margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
      <Grid container spacing={4} justifyContent="center" style={{height:'auto'}} >
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: 'white' }} style={{}}>
            <CardContent style={{textAlign:'center'}}>
              <img src={aboutimg} alt='' style={{width:'100%', borderRadius:'4vh'}}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    </ThemeProvider>
  );
};

export default MainHeroAbout;
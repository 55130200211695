import { useState } from 'react';

const usePostRequest = (url, data, onSuccess, onFailure) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const postData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch');
      }

      const responseData = await response.json();
      setIsLoading(false);
      onSuccess(responseData);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
      onFailure(error);
    }
  };

  const reset = () => {
    setError(null);
    setIsLoading(false);
  };

  return {
    mutate: postData,
    isLoading,
    error,
    reset,
  };
};

export default usePostRequest;

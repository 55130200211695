import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";
import HealthAccess from "./HealthAccess.jsx";
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import AboutCheckups from "./AboutCheckups.jsx";
import OurPartners from "./OurPartners.jsx";
import OurTeam from "./OurTeam.jsx";
import MainHeroAbout from "./MainHeroAbout.jsx";

function About() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <Navbar/>
      
      <div onClick={scrollToTop} className={`scroll-up ${goUp ? "show-scroll" : ""}`}>
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    <MainHeroAbout/>
    <HealthAccess/>
    <AboutCheckups/>
    <OurPartners/>
  {/*   <OurClients/> */}
    <OurTeam/>
    <Footer/>
    </div>
  );
}

export default About;

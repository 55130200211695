import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import whitearrow from "../HomeAssets/white-arrow.png";
import { createTheme, responsiveFontSizes,ThemeProvider } from '@mui/material/styles';
import {Link} from 'react-router-dom'

let theme = createTheme();
theme = responsiveFontSizes(theme);

const  IbmSection= () => {
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ padding:{xs:2,md:4}, width:{xs:'96%',md:'90%'},backgroundColor: '#FFFFFF',  margin: '80px auto',borderRadius:'4vh'  }}>
      <Grid container spacing={2} style={{marginLeft: '0.5vh'}}>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" gutterBottom 
          sx={{backgroundColor:'rgba(202,89,134,0.4)',
               padding:'15px', 
               fontSize:{xs:'2vh',md:'3vh'},
               width:{xs:'60%',md:'35%'},
               marginLeft:{xs:"1vh",md:'3.5vh'},
               borderRadius:'2.5vh',
               fontFamily:'Inter Regular',
               marginTop:'2.5vh',
               color:'#113356',}}>
            IBM Z and LinuxONE Community
          </Typography>
          <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
               CheckUps Leverages IBM LinuxONE for High-Performance AI Inferencing and Secure Data Serving
              </Typography>
              <Typography  sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontStyle:'italic'}} >
              IBM LinuxONE 4 offers an ideal infrastructure for predictive AI workloads, enabling businesses to drive value with speed and scale
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              By: Dr Moka Lantum, CEO, CheckUps (Nairobi, Kenya)
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              We recognize the important role that infrastructure plays in the success of our projects
              and data management initiatives. With its on-chip AI accelerator, IBM LinuxONE 4 is engineered for speed and scale, providing
              a dedicated resource for AI inferencing to enable huge levels of throughput. IBM internal testing found that IBM LinuxONE
              Emperor 4 can process up to 3.5 million inference requests per second with 1ms
              response time using a Credit Card Fraud Detection model1. By exploring the potential, startups and small to medium-sized 
              businesses may have the opportunity to enhance their operations and potentially deliver additional value to their customers.

              </Typography>
          
          <Link to="/resources/hyper-protect-ai-on-linuxone">
          <Button variant="contained" color="primary" sx={{backgroundColor:'#113356',fontFamily:'Inter', borderRadius:'1vh', width:'40%', fontSize:{xs:'2vh',md:'3.5vh'},padding:'1.5vh 1.5vh',marginTop:'1vh',textTransform:"none"}}>
            Read More
            <img src={whitearrow} alt='' style={{width: '2.5vh',marginleft: '1vh'}}/>
          </Button>
          </Link>
        </Grid>
       
      </Grid>
    </Box>
    </ThemeProvider>
  );
};

export default IbmSection;
import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import Navbar from './Navbar';
import Footer from './Footer';


const CheckupsDisclaimer = () => {
  return (
    <div className="section-container">
    <Navbar/>
  
    <Box  sx={{  padding:{xs:2,md:4}, width:{xs:'96%',md:'95%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
      <Grid container spacing={4} justifyContent="center" style={{height:'auto',marginTop:'-20px'}} >
        <Grid item xs={12} >
          <Card sx={{ backgroundColor: '#FCFAF8' }} style={{paddingTop:'0vh'}}>
            <CardContent >
               <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
               Disclaimer for Checkups
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Last updated: June 5, 2024
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The information provided by Checkups, its parent, holding, sister, or subsidiary companies ("we," "us," or "our") on our website (the "Site") is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the Site or reliance on any information provided on the Site. Your use of the Site and your reliance on any information on the Site is solely at your own risk.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
              </Typography>
              <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'3.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Compliance Disclaimer:
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              Checkups, its parent, holding, sister, or subsidiary companies strive to ensure our policies and practices are compliant with applicable laws and regulations, including GDPR, CCPA, CPRA, and CalOPPA. However, nothing on this Site shall be considered legal advice and no attorney-client relationship is established. We cannot guarantee full compliance, as only a lawyer can provide such assurance.
              </Typography>
              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
              If you have any questions about this Disclaimer, You can contact Us:
              </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                 By email: < a href='mailto:info@checkUpsmed.com' target="blank">info@checkupsmed.com</a>
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                  By phone: (+254) (0) 111 050 290 
                </Typography>
                <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'2.5vh'}, color:'#113356'}}>
                By WhatsApp: <  a href="https://wa.me/+25411050290" target="blank"> +25411050290</a>
                </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
    <Footer/>
    </div>
  );
};

export default CheckupsDisclaimer;
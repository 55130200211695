import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import mokaicon from "../HomeAssets/Moka.jpg";
import reneeicon from "../HomeAssets/Renee.jpg"
import iconimage from "../HomeAssets/Frame 427319286.png";

const OurTeam = () => {
  return (
    <Box  sx={{  padding:{xs:2,md:2}, width:{xs:'96%',md:'95%'}, margin: '80px auto', backgroundColor:'#FCFAF8', borderRadius:'4vh'}}>
   
      <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'5vh',md:'7vh'},fontFamily: 'Inter Display', textAlign:'center', color:'#113356'}}>
      Meet the team
      </Typography>
      <img src={iconimage} alt='' style={{width:'20%'}}/>
      <Grid container spacing={4} style={{height:'auto',marginTop:'-150px', backgroundColor:'white'}} >
        <Grid item xs={12} md={5} sx={{ backgroundColor: 'white' }} style={{paddingTop:'30px'}}>
               <img src={mokaicon} alt='' style={{width:'100%',height:'auto', borderRadius:'5vh'}}/>
        </Grid>
        <Grid item xs={12} md={7} sx={{ backgroundColor: '#FCFAF8' }} style={{paddingTop:'0vh'}}> 
            <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'4.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Dr. Moka Lantum
              </Typography>
              <Typography  sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'1vh'}, color:'#113356'}} >
              Dr. Moka Lantum, a visionary in healthtech, is transforming African healthcare as CEO and Co-Founder of CheckUps Medical. This platform provides tech-enabled urgent care and home delivery to over 300,000 uninsured or vulnerable patients in Kenya and South Sudan.

              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'1vh'}, color:'#113356'}}>
              Dr. Lantum's career includes senior roles at Excellus BlueCross BlueShield and Eastman Kodak, where he led projects optimizing care access and cutting costs through data analytics, saving millions. He founded MicroClinic Technologies, creating Africa's first government-adopted national electronic medical record system, used in over 200 facilities
              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'1vh'}, color:'#113356'}}>
              An Ashoka Fellow and Foreign Policy Global Thinker, Dr. Lantum's work attracts strategic partnerships, including with Philips Foundation. His goal is to scale CheckUps into Africa's first mass-market health brand, promoting sustainability and shared value, and enhancing healthcare access across the continent.
              </Typography>
        </Grid>

        <Grid item xs={12} md={5} style={{paddingTop:'20vh'}}>
          
              <img src={reneeicon} alt='' style={{width:'100%',height:'auto',borderRadius:'5vh'}}/>
        </Grid>
        <Grid item xs={12} md={7}sx={{ backgroundColor: '#FCFAF8' }} style={{paddingTop:'15vh'}} >
          <Grid></Grid>
        
            <Typography variant="h4" gutterBottom sx={{fontSize:{xs:'2.5vh',md:'4.5vh'},fontFamily: 'Inter Display',margin:{xs:"1vh",md:'2.5vh'}, color:'#113356', fontWeight:'bold'}}>
              Renee Ngamau
              </Typography>
              <Typography  sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'1vh'}, color:'#113356'}}>
              Renee Ngamau is a multifaceted professional with extensive expertise in law, mass communication, and women's advancement. She holds an LL.M. in Banking and Finance from the University of London and an LL.B. from the University of Nairobi. Her legal career includes roles as in-house legal counsel at Total Kenya Ltd. and corporate law practice at Freshfields, Bruckhaus, Derringer in London.

              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'1vh'}, color:'#113356'}}>
              Renee has also made significant contributions to women's business development, mentoring over 3000 women using her background as an award-winning broadcast journalist. She is the co-founder of CheckUps and serves on the global board of Amnesty International. As chair of CleanStart Africa, she has driven remarkable growth in the organization's budget, donor base, and programs, expanding initiatives from 5 to 19 prisons to support formerly incarcerated women.

              </Typography>

              <Typography sx={{fontFamily: 'Inter Regular', fontSize:{xs:'2.5vh',md:'3.5vh'}, margin:{xs:"1vh",md:'1vh'}, color:'#113356'}}>
              Appointed by Kenya's Chief Justice to the National Council on Administration of Justice Committee, Renee played a pivotal role in reforming sexual and gender-based violence laws. Her leadership in transforming high-impact ventures and humanitarian organizations underscores her status as a highly respected and influential leader.
              </Typography>
        </Grid>
        </Grid>
    

    </Box>
  );
};

export default OurTeam;